<template>
    <div class="container">
        <HeaderComponent title="消息中心" :showBack="false" />
        <ChatRoomList></ChatRoomList>
    </div>
</template>

<script>
import HeaderComponent from "../components/commonComponent/Header.vue";
import ChatRoomList from "../components/pages/ChatRoomList.vue";
export default {
    components: {
        HeaderComponent,
        ChatRoomList
    }
};
</script>