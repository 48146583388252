<template>
    <div class="category-wrapper">
        <titleText title="熱門類型" />
        <div class="category-container">
            <div v-for="(item, index) in categories" :key="index" class="category-item"
                @click="switchswitchHandle(item)">
                <img :src="item.imgUrl" :alt="item.name" class="category-image" />
                <p class="category-name">{{ item.name }}</p>
            </div>
        </div>
    </div>
</template>

<script>
import titleText from "../commonComponent/TitleText.vue";
export default {
    components: {
        titleText,
    },
    data() {
        return {
            categories: [],
        };
    },
    mounted() {
        this.$post('api/v1/category/', {}).then((res) => {
            this.categories = res.body.data;
            window.sessionStorage.setItem('staticUrl', res.body.staticUrl)
        }, (err) => {
            this.$msgError(err.message);
        });
    },
    methods: {
        switchswitchHandle(item) {
            const actions = {
                goActionFunc: () => this.goActionFunc(item.is_params),
            };
            if (actions[item.action]) actions[item.action]();
        },
        goActionFunc(params) {
            this.$router.push({ name: "allPage", params: { t: params } });
        },
    }
};
</script>

<style scoped>
/* 外部容器：设置左右滑动 */
.category-wrapper {
    white-space: nowrap;
    width: 98%;
    margin: auto;
    font-size: 12px;
}

/* 使用 Flexbox 进行布局 */
.category-container {
    overflow-x: auto;
    /* 允许左右滚动 */
    display: flex;
    flex-wrap: nowrap;
    min-width: 100%;
    /* 确保容器至少占满一行 */
}

.category-item {
    flex-basis: 100px;
    /* 设置宽度为 100px，或者随情况动态调整 */
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 5px;
    text-align: center;
}

.category-name,
.category-image {
    width: 100%;
    /* 让文字和图片宽度自动适应容器 */
}


.category-image {
    height: 100px;
    object-fit: cover;
    border-radius: 5px;
}

.category-name {
    margin-top: 10px;
    font-size: 12px;
    background: #d7dae0;
    color: #fff;
    width: 100px;
    /* 与图片一致 */
    padding: 5px 0;
    border-radius: 3px;
}
</style>
