<template>
    <div class="container">
        <!-- Header -->
        <HeaderComponent title="我的媒体集" :showBack="true" />

        <!-- 图片展示区 -->
        <div class="media-gallery">
            <div v-for="(file, index) in mediaFiles" :key="index" class="media-item" @click="showImage(file, index)">
                <div class="media-wrapper">
                    <img :src="file" alt="media" />
                </div>
            </div>
        </div>

        <!-- 放大图片区域 -->
        <div v-if="isImageViewerVisible" class="image-viewer">
            <div class="viewer-overlay" @click="closeImageViewer"></div>
            <div class="viewer-content">
                <div class="image-container">
                    <img :src="currentImage" alt="media" />
                </div>
                <div class="viewer-controls">
                    <button @click="prevImage">上一个</button>
                    <button @click="nextImage">下一个</button>
                    <button @click="copyLink">复制链接</button>
                    <button @click="closeImageViewer">关闭</button>
                </div>
            </div>
        </div>

        <scrollTop :scrollDom="'.container'"></scrollTop>
    </div>
</template>

<script>
import HeaderComponent from "../commonComponent/Header.vue";
import scrollTop from "../commonComponent/Scroll.vue";

export default {
    components: {
        HeaderComponent,
        scrollTop
    },
    data() {
        return {
            baseApiUrl: "api/v1/getMedia/",
            mediaFiles: [], // 图片链接列表
            isImageViewerVisible: false, // 是否显示图片查看器
            currentImage: "", // 当前放大的图片链接
            currentIndex: 0, // 当前图片在列表中的索引
        };
    },
    mounted() {
        this.getMediaApi();
    },
    methods: {
        // 获取图片数据
        getMediaApi() {
            let mediaList = window.sessionStorage.getItem("mediaList")
            if (!mediaList) {
                this.$loading.show();
                this.$post(this.baseApiUrl, {}).then(res => {
                    this.mediaFiles = res.body.file_names;
                    window.sessionStorage.setItem("mediaList", JSON.stringify(res.body.file_names))
                    this.$loading.hide();
                }).catch(err => {
                    this.$loading.hide();
                    this.$msgError(err.message);
                });
            } else {
                this.mediaFiles = JSON.parse(mediaList)
            }
        },

        // 显示图片查看器
        showImage(file, index) {
            this.currentImage = file;
            this.currentIndex = index;
            this.isImageViewerVisible = true;
        },

        // 关闭图片查看器
        closeImageViewer() {
            this.isImageViewerVisible = false;
        },

        // 查看上一张图片
        prevImage() {
            if (this.currentIndex > 0) {
                this.currentIndex--;
                this.currentImage = this.mediaFiles[this.currentIndex];
            } else {
                this.$msgError("已经是第一张图片了！");
            }
        },

        // 查看下一张图片
        nextImage() {
            if (this.currentIndex < this.mediaFiles.length - 1) {
                this.currentIndex++;
                this.currentImage = this.mediaFiles[this.currentIndex];
            } else {
                this.$msgError("已经是最后一张图片了！");
            }
        },

        // 复制链接
        copyLink() {
            const textarea = document.createElement('textarea');
            textarea.value = this.currentImage;
            document.body.appendChild(textarea);
            textarea.select();
            document.execCommand('copy');
            document.body.removeChild(textarea);
            this.$msgSuccess("链接已复制！");
        },
    },
};
</script>

<style scoped>
/* 图片展示区域样式 */
.media-gallery {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    /* 布局自适应 */
    gap: 10px;
    margin-top: 20px;
    width: 95%;
    margin: auto;
}

.media-item {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden;
    transition: transform 0.3s;
    aspect-ratio: 1/1;
    /* 强制保持正方形 */
    background: #f9f9f9;
    /* 背景颜色，防止空白区域影响观感 */
}

.media-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(120deg, #fbc7d4, #9796f0);
    overflow: hidden;
}

.media-wrapper img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    /* 图片不会拉伸，保持宽高比 */
}

.media-item:hover {
    transform: scale(1.05);
    /* 放大效果 */
}

/* 放大图片查看器样式 */
.image-viewer {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.viewer-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
}

.viewer-content {
    position: relative;
    background: #7a7a7a;
    padding: 20px;
    border-radius: 10px;
    z-index: 1001;
    max-width: 85%;
    max-height: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.viewer-content img {
    max-width: 100%;
    max-height: 80vh;
    object-fit: contain;
}

.viewer-controls {
    margin-top: 10px;
    display: flex;
    gap: 10px;
}

.viewer-controls button {
    padding: 10px 15px;
    border: none;
    border-radius: 5px;
    background: #007bff;
    color: #fff;
    cursor: pointer;
    transition: background 0.3s;
}

.viewer-controls button:hover {
    background: #0056b3;
}
</style>
