<template>
    <div class="container">
        <HeaderComponent title="詳情" :showBack="true" />

        <!-- 信息展示 -->
        <div class="video-msg">
            <div class="video-title">{{ details.title }}</div>
            <div class="divider"></div>
            <div class="description">{{ details.msg }}</div>
            <div class="tags">
                <el-tag style="margin-right: 5px;">类型:</el-tag>
                <span v-for="(tag, index) in details.tags" :key="index">
                    <el-tag class="tag-item" @click="goTagFunc(tag)">{{ tag }}</el-tag>
                </span>
            </div>
        </div>

        <!-- 主容器 -->
        <div class="main-wrapper">
            <!-- 视频播放容器 -->
            <div class="video-wrapper">
                <!-- 操作台 -->
                <div class="controls">
                    <div @click="handleClickActor(details.name)" class="controlsItem">
                        <i class="el-icon-user"></i>
                        <div class="actorName">{{ details.name }}</div>
                    </div>
                    <div @click="goCollFunc" class="controlsItem">
                        <i class="el-icon-thumb"></i>
                        <div class="actorName">{{ isColl ? '取消收藏' : '收藏' }}</div>
                    </div>
                    <div @click="goZhFunc" class="controlsItem">
                        <i class="el-icon-monitor"></i>
                        <div class="actorName">{{ details.language ? '中文字幕' : '无字幕' }}</div>
                    </div>
                    <div @click="rewind" class="controlsItem">
                        <i class="el-icon-d-arrow-left"></i>
                        <div class="actorName">快退</div>
                    </div>
                    <div @click="pause" class="controlsItem">
                        <i class="el-icon-video-pause"></i>
                        <div class="actorName">暂停</div>
                    </div>
                    <div @click="fastForward" class="controlsItem">
                        <i class="el-icon-d-arrow-right"></i>
                        <div class="actorName">快进</div>
                    </div>
                </div>

                <div class="video-player">
                    <div class="video-container">
                        <div class="video-cover" :style="{ backgroundImage: `url(${details.url})` }"></div>
                        <video ref="video" :poster="details.url" controls @fullscreenchange="handleFullscreenChange"
                            @touchstart="handleTouchStart" @touchend="handleTouchEnd"></video>
                        <div class="play-button" @click="playerVideoFunc(details.id)">
                            <img src="@/assets/images/player.png" alt="Play">
                        </div>
                    </div>
                </div>
            </div>

            <!-- 熱門推薦 -->
            <div class="recommend-wrapper" v-show="hotRecommendList.length >= 1">
                <titleText title="熱門推薦" />
                <div class="recommend-list">
                    <div class="recommend-item" v-for="(rec, index) in hotRecommendList" :key="index"
                        @click="handleHot(rec.id)">
                        <img class="recommend-thumb" :src="rec.url" alt="推薦">
                        <div class="recommend-name">{{ rec.name }}</div>
                    </div>
                </div>
            </div>
        </div>

        <!-- 页脚 -->
        <!-- <Footer /> -->
    </div>
</template>

<script>
import Hls from 'hls.js';
import HeaderComponent from "../commonComponent/Header.vue";
import Footer from '../commonComponent/Footer.vue';
import titleText from '../commonComponent/TitleText.vue';

export default {
    components: {
        HeaderComponent, Footer, titleText
    },
    data() {
        return {
            details: {}, // 视频详情
            isColl: false, // 是否收藏
            hotRecommendList: [], // 热门推荐列表
            videoSrc: "", // 播放链接
            touchStartTime: 0,
            touchStartX: 0,
        };
    },
    created() {
        let vid = this.$route.params.id; // 传入的id
        if (vid) {
            window.sessionStorage.setItem("vid", vid);
        } else {
            vid = window.sessionStorage.getItem("vid");
        }
        this.detailApi(vid);
    },
    methods: {
        // 获取视频详情
        detailApi(vid) {
            this.$loading.show();
            this.$post("api/v1/getVideo/", { vid }).then(res => {
                this.details = res.body.data;
                this.isColl = res.body.isColl;
                this.hotRecommendList = res.body.hot;
                this.$msgSuccess(res.body.msg);
                this.$loading.hide();
            }, err => {
                this.$loading.hide();
                this.$msgError(err.message);
            });
        },

        // 播放按钮点击时检查视频链接
        playerVideoFunc(vid) {
            this.$loading.show();
            this.$post("api/v1/checkVideo/", { vid }).then(res => {
                this.videoSrc = res.body.playUrl;
                this.play();
                this.$loading.hide();
            }, err => {
                this.$loading.hide();
                this.$msgError(err.message);
            });
        },

        // 播放视频
        play() {
            if (Hls.isSupported()) {
                const hls = new Hls();
                hls.loadSource(this.videoSrc);
                hls.attachMedia(this.$refs.video);
                hls.on(Hls.Events.MANIFEST_PARSED, () => {
                    this.$refs.video.play();
                    this.$refs.video.controls = true;
                    this.hidePlayButton();
                });
            } else if (this.$refs.video.canPlayType('application/vnd.apple.mpegurl')) {
                this.$refs.video.src = this.videoSrc;
                this.$refs.video.play();
                this.$refs.video.controls = true;
                this.hidePlayButton();
            }
        },

        // 暂停视频
        pause() {
            this.$refs.video.pause();
        },

        // 快进
        fastForward() {
            this.$refs.video.currentTime += 10;
        },

        // 快退
        rewind() {
            this.$refs.video.currentTime -= 10;
        },

        // 隐藏播放按钮
        hidePlayButton() {
            this.$refs.video.controls = true;
            this.$el.querySelector('.play-button').style.display = 'none';
        },

        // 点击演员
        handleClickActor(name) {
            let route = this.$router.resolve({ name: "actor", params: { name } });
            window.open(route.href, '_blank');
        },

        // 处理收藏点击
        goCollFunc() {
            const vid = this.details.id;
            this.$post("api/v1/collect/", { vid }).then(res => {
                this.isColl = res.body.isColl === 0;
                const showStr = res.body.isColl === 1 ? "取消收藏" : "收藏成功";
                this.$msgSuccess(showStr);
            }, err => {
                this.$msgError(err.message);
            });
        },

        // 点击标签
        goTagFunc(tagName) {
            window.sessionStorage.setItem("tagName", tagName);
            this.$router.push({ name: "allPage", params: { t: "tag" } });
        },

        // 切换中文字幕
        goZhFunc() {
            this.$router.push({ name: "allPage", params: { t: "isZh" } });
        },

        // 全屏切换
        handleFullscreenChange() {
            if (document.fullscreenElement) {
                console.log('已进入全屏');
            } else {
                console.log('已退出全屏');
            }
        },

        // 移动端滑动操作
        handleTouchStart(event) {
            this.touchStartTime = Date.now();
            this.touchStartX = event.touches[0].clientX;
        },
        handleTouchEnd(event) {
            const touchEndTime = Date.now();
            const touchEndX = event.changedTouches[0].clientX;
            const touchDuration = touchEndTime - this.touchStartTime;
            const touchDistance = touchEndX - this.touchStartX;

            if (touchDuration < 300) { // 快速触摸
                if (touchDistance > 50) {
                    this.fastForward();
                } else if (touchDistance < -50) {
                    this.rewind();
                }
            }
        },

        // 点击推荐视频
        handleHot(vid) {
            let route = this.$router.resolve({ name: "details", params: { id: vid } });
            window.open(route.href, '_blank');
            // this.$router.push({ name: "details", params: { id: vid } });
        },
    },
};
</script>

<style scoped>
/* 视频详情展示 */
.video-msg {
    width: 90%;
    margin: 10px auto;
}

.video-title {
    font-size: 24px;
    font-weight: bold;
    word-wrap: break-word;
}

.divider {
    border: 1px solid rgb(199, 198, 193);
    margin: 5px 0;
}

.description {
    margin-top: 5px;
    color: rgb(125, 124, 117);
}

.tags {
    margin-top: 5px;
}

.tag-item {
    cursor: pointer;
}

/* 主容器，双列布局 */
.main-wrapper {
    display: flex;
    justify-content: space-between;
    width: 90%;
    max-width: 1200px;
    margin: 0 auto;
}

.video-wrapper {
    flex: 1;
    margin-right: 20px;
}

.video-player {
    width: 100%;
}

.video-container {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
    /* 16:9 比例 */
}

.video-container video,
.video-cover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    /* height: 100%; */
    height: 95%;
    object-fit: contain;
}

.play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    cursor: pointer;
}

.play-button img {
    width: 64px;
    height: 64px;
}

.controls {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    background: black;
    border-radius: 10px 10px 0 0;
    color: #fff;
    padding: 5px 0;
}

.controlsItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    padding: 5px;
    transition: background-color 0.3s;
    width: 80px;
    text-align: center;
}

.controlsItem:hover {
    background-color: #e0e0e0;
}

.actorName {
    font-size: 12px;
    margin-top: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

/* 热门推荐 */
.recommend-wrapper {
    width: 25%;
    overflow-y: auto;
    max-height: 500px;
    overflow-x: hidden;
    /* 取消水平滚动 */
}

.recommend-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}

.recommend-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.recommend-item {
    padding: 10px;
    border: 1px solid #e0e0e0;
    border-radius: 5px;
    transition: 0.3s all;
    text-align: center;
    max-width: 100%;
}

.recommend-item:hover {
    background-color: #f0f0f0;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.recommend-thumb {
    width: 100%;
    height: 185px;
    object-fit: contain;
    border-radius: 5px;
}

.recommend-name {
    font-size: 12px;
    margin-top: 5px;
    color: #333;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

/* 响应式布局，适配移动端 */
@media (max-width: 768px) {
    .main-wrapper {
        flex-direction: column;
    }

    .video-wrapper {
        margin-right: 0;
    }

    .recommend-wrapper {
        width: 100%;
        max-width: 100%;
    }

    .recommend-list {
        flex-direction: row;
        overflow-x: auto;
        gap: 5px;
    }

    .recommend-item {
        flex: none;
        width: 30%;
    }

    .recommend-thumb {
        height: 165px;
    }

    .recommend-name {
        font-size: 12px;
    }
}
</style>
