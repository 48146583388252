<template>
    <div :class="['tabs', tabPosition === 'top' ? 'tabs-top' : 'tabs-bottom']">
        <input v-for="(tab, index) in tabItems" :key="tab.name" type="radio" :id="`tab-${index}`" v-model="selectedTab"
            :value="tab.name" @change="tabChanged(tab.name)" v-if="tab.isShow" />
        <label v-for="(tab, index) in tabItems" :key="`${tab.name}-label`" :for="`tab-${index}`"
            :class="['tab-item', { selected: tab.name === selectedTab }]" v-if="tab.isShow">
            <div class="icon-container">
                <!-- 注意：根据 Vue中 svg 图标的 class 动态绑定规则 -->
                <svg-icon :name="tab.icon" :class="{ 'selected-icon': tab.name === selectedTab }" />
                <!-- 选中状态才显示 -->
                <div v-if="tab.name === selectedTab" class="wave"></div>
            </div>
            <div class="tab-name">{{ tab.tarbar }}</div>
        </label>
    </div>
</template>

<script>
export default {
    props: {
        activeTab: {
            type: String,
            required: true,
        },
        tabPosition: {
            type: String,
            default: 'bottom',
        },
        tabItems: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            selectedTab: this.activeTab,
        };
    },
    methods: {
        tabChanged(newTab) {
            this.$emit('update-tab', newTab);
        },
    },
    watch: {
        activeTab(newTab) {
            this.selectedTab = newTab;
        },
    },
};
</script>


<style scoped>
.tabs {
    background: var(--background);
    width: 100%;
    height: var(--height);
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: fixed;
    bottom: 0;
    border-radius: 15px 15px 0px 0px;
    box-shadow: 0 10px 30px rgba(65, 72, 86, 0.1);
}

/* 支持顶部 tab */
.tabs-top {
    top: 0;
    border-radius: 0px 0px 15px 15px;
}

/* 隐藏 radio 按钮 */
.tabs input {
    display: none;
}

/* Tab 项按钮核心布局 */
.tab-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
}

.tab-name {
    margin: 3px;
    font-size: 14px;
}

.icon-container {
    position: relative;
    width: 26px;
    /* 图标容器大小设为26px */
    height: 26px;
    /* 图标容器大小设为26px */
}

/* 图标默认显示灰色线条 */
.icon-container svg {
    width: 100%;
    height: 100%;
    fill: none;
    stroke: var(--icon-color);
    /* 默认灰色描边 */
    transition: stroke 0.3s ease-in;
}

/* 选中时变为黑色线条 */
.selected-icon {
    stroke: var(--icon-color-selected) !important;
}

/* 选中的page 图标 */
.wave {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: var(--wave-color);
    border-radius: 50% 50% 0 0 / 100% 100% 0 0;
    transform-origin: center bottom;
    animation: wave-rise var(--wave-am-ts) ease-in forwards;
    z-index: -1;
    /* 确保波浪在图标下方 */
}

/* 水波波动动画 */
@keyframes wave-rise {
    0% {
        height: 0%;
    }

    100% {
        height: var(--wave-height);
    }
}
</style>
