<template>
    <div class="container">
        <HeaderComponent title="所有数据" :showBack="true" />

        <div style="width: 98%; margin: auto;">
            <!-- 搜索组件 -->
            <Search v-model="searchKeyword" @search="handleSearch" />

            <!-- 顶部分页组件 -->
            <Pagination :totalItems="totalCount" :pageSize="pageSize" :currentPage="currentPage"
                @page-change="handlePageChange" />

            <div class="detailBox">
                <imageList :allList="allList" :category="category" />
            </div>

            <!-- 底部分页组件 -->
            <Pagination :totalItems="totalCount" :pageSize="pageSize" :currentPage="currentPage"
                @page-change="handlePageChange" />
        </div>

        <Footer></Footer>

        <!-- 回到顶部 -->
        <scrollTop :scrollDom="'.container'"></scrollTop>
    </div>
</template>

<script>
import HeaderComponent from "../commonComponent/Header.vue";
import Search from "../commonComponent/Search.vue";
import imageList from "../commonComponent/imageListComponent.vue";
import Footer from "../commonComponent/Footer.vue";
import scrollTop from "../commonComponent/Scroll.vue";
import Pagination from "../commonComponent/Pagination.vue";

export default {
    components: {
        HeaderComponent,
        imageList,
        Footer,
        scrollTop,
        Pagination,
        Search
    },
    data() {
        return {
            category: "",       // 当前类别
            allList: [],          // 数据列表
            totalCount: 0,        // 总数据量
            pageSize: 20,         // 每页显示的条数
            currentPage: 1,       // 当前页码
            searchKeyword: "",  // 搜索关键字
        };
    },
    created() {
        this.initializePage(); // 初始化页面并加载数据
    },
    methods: {
        // 初始化页面
        initializePage() {
            let category = this.$route.params.t || window.sessionStorage.getItem("allPageCategory");
            if (category) {
                window.sessionStorage.setItem("allPageCategory", category);
            }
            this.category = category;

            let savedPage = window.sessionStorage.getItem("allPage" + category);
            this.currentPage = savedPage ? Number(savedPage) : 1;

            this.getAllFunc(this.category, this.currentPage); // 加载数据
        },

        // 获取数据
        getAllFunc(category, page) {
            this.$loading.show();
            window.sessionStorage.setItem("allPage" + category, page); // 保存当前页码

            const tagName = window.sessionStorage.getItem("tagName") || "";

            this.$post("api/v1/getMoreData/", {
                isType: category,
                pageNum: page,
                tag: tagName,
                search: this.searchKeyword
            }).then(
                (res) => {
                    this.allList = res.body.data;
                    this.totalCount = res.body.count;
                    this.$loading.hide();
                },
                (err) => {
                    this.$msgError(err.message);
                    this.$loading.hide();
                }
            );
        },

        // 处理分页变化时的逻辑
        handlePageChange(page) {
            this.currentPage = page;                // 更新当前页码
            this.getAllFunc(this.category, page);   // 获取新的数据
        },

        // 处理搜索
        handleSearch() {
            this.currentPage = 1; // 搜索时重置为第一页
            this.getAllFunc(this.category, this.currentPage);  // 搜索并加载第一页
        }
    }
};
</script>

<style scoped></style>
