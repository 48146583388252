<!-- 
Component Use Test
handleDelete() {
    this.$customModal.confirm({
        title: '提醒',
        content: '',
        showCancel: false,  // 是否展示取消按钮
        imgUrl: "", // 是否有图片
        onOK: () => {
        },
        onCancel: () => {
        },
    });
} 
-->

<template>
  <div v-if="visible" class="modal-mask">
    <div class="modal-wrapper">
      <div class="modal-container">
        <header class="modal-header">
          <h2 class="modal-title">{{ title }}</h2>
          <button class="modal-close-btn" @click="handleCancel">✕</button>
        </header>
        <section class="modal-body">
          <p class="modal-content" v-html="content"></p>
          <img :src="imgUrl" v-if="imgUrl">
        </section>
        <footer class="modal-footer">
          <button @click="handleCancel" class="modal-btn cancel-btn" v-if="showCancel">取 消</button>
          <button @click="handleOK" class="modal-btn confirm-btn">确 定</button>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CustomModal',
  data() {
    return {
      visible: false,
      title: '',
      content: '',
      showCancel: false,
      imgUrl: "",
      onOK: null,
      onCancel: null,
    };
  },
  methods: {
    handleOK() {
      if (this.onOK) this.onOK();
      this.hide();
    },
    handleCancel() {
      if (this.onCancel) this.onCancel();
      this.hide();
    },
    show({ title, content, onOK, onCancel, showCancel, imgUrl, }) {
      this.title = title || '警告';
      this.content = content || "账号禁止共享 違者將封禁賬號處理";
      this.showCancel = showCancel
      this.imgUrl = imgUrl;
      this.onOK = onOK;
      this.onCancel = onCancel;
      this.visible = true;      
    },
    hide() {
      this.visible = false;
    }
  }
};
</script>

<style scoped>
/* 背景遮罩 */
.modal-mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* 优化为半透明黑色背景 */
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s ease;
  z-index: 1000;
}

.modal-wrapper {
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-container {
  background-color: #fff;
  border-radius: 10px;
  /* 更加圆润的边角 */
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  /* 强化阴影 */
  text-align: center;
  overflow: hidden;
  /* 使得边角更美观 */
  width: 50%;
}

/* 标题部分 */
.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background-color: #f5f5f5;
  border-bottom: 1px solid #eaeaea;
}

.modal-title {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.modal-close-btn {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  line-height: 1;
  color: #888;
  transition: color 0.3s ease;
}

.modal-close-btn:hover {
  color: #ff5c5c;
}

/* 内容部分 */
.modal-body {
  padding: 20px;
  max-height: 200px;
  /* 设置最大高度为3行左右（根据字体大小） */
  overflow-y: auto;
  /* 当内容超过最大高度时显示滚动条 */
}

.modal-content {
  font-size: 16px;
  color: #ff6600;
  /* 橙色文字 */
  font-weight: 500;
}

/* 页脚部分 */
.modal-footer {
  display: flex;
  justify-content: center;
  padding: 15px 0;
  background-color: #f5f5f5;
  border-top: 1px solid #eaeaea;
}

/* 按钮样式 */
.modal-btn {
  padding: 10px 25px;
  margin: 0 10px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 16px;
}

/* 确定按钮 */
.confirm-btn {
  background-color: #2ac8dd;
  color: white;
}

.confirm-btn:hover {
  background-color: #5a00d1;
}

/* 取消按钮 */
.cancel-btn {
  background-color: #cccccc;
  color: #333;
}

.cancel-btn:hover {
  background-color: #bbbbbb;
}

@media (max-width: 768px) {
  .modal-container {
        width: 100%;
    }
}
</style>
