<template>
    <div class="detailBox" v-loading="loading">
        <titleText :title="isTitle" @handleFunc="handleFunc(isType)" />
        <imageList :allList="images" :loading="loading" :showFree="isType" :isShowMore="isShowMore" />
        <el-empty description="暫無更多" v-if="images.length == 0"></el-empty>
    </div>
</template>


<script>
import imageList from "./imageListComponent.vue"
import titleText from "./TitleText.vue";
export default {
    name: "freeComponent",
    components: {
        imageList,
        titleText
    },
    props: {
        isType: String,
        isSize: String,
        isTitle: String,
        isShowMore: Boolean
    },
    data() {
        return {
            loading: true,
            images: [],
        };
    },
    mounted() {
        this.getFreeInfo();
    },
    methods: {
        async getFreeInfo() {
            this.loading = true
            let propsVal = this.$props;
            this.$get("api/v1/getFreeInfo/", {
                type: propsVal.isType,
                pageSize: propsVal.isSize
            }).then(res => {
                this.images = res.body.data;
                this.loading = false
            }, err => {
                this.$msgError(err.message)
            })
        },
        handleFunc(t) {
            this.$router.push({ name: "allPage", params: { t } });
        },
    },
};
</script>

<style scoped></style>