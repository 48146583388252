<template>
  <div class="chat-page">
    <!-- Header -->
    <HeaderComponent :title="roomName" :showBack="true" :showMarQuee="true" :marqueeText="marqueeText"
      headerCss="header-fixed" />

    <!-- Chat 内容区域 -->
    <div ref="chatContent" class="chat-content" @scroll="handleScroll">
      <!-- 加载历史消息时的提示 -->
      <div v-if="isLoadingHistory" class="loading-container">
        <div class="loading">加載中...</div>
      </div>

      <!-- 没有更多消息的提示 -->
      <div v-if="noMoreMessages" class="loading-container">
        <div class="no-more-messages">暫無更多消息</div>
      </div>

      <!-- 展示消息 -->
      <div v-for="(message, index) in paginatedMessages" :key="index" class="chat-item">
        <!-- 消息盒子方向 -->
        <div :class="['message', message.nickname === userinfo.nickname ? 'message-right' : 'message-left']">
          <img class="avatar" :src="userinfo.avatar" alt="Avatar" @error="handleImageError($event, 'avatar')" />

          <!-- 文字消息 -->
          <div v-if="message.msgType === 'text'" class="text-bubble">
            {{ message.content }}
          </div>

          <!-- 图片消息 -->
          <div v-if="message.msgType === 'image'" class="image-bubble">
            <img :src="message.content" class="image-content" @click="viewImage(message.content)"
              @error="handleImageError($event, 'chat')" />
          </div>
        </div>
      </div>
    </div>

    <!-- 回到最新消息按钮 -->
    <div v-if="showScrollToBottomButton" class="scroll-to-bottom" @click="scrollToLatestWithAnimation">
      回到最新消息
    </div>

    <!-- 输入框，发送消息 -->
    <div class="chat-input">
      <input v-model="inputMessage" type="text" placeholder="请输入您的信息" />

      <!-- 隐藏的文件选择框 (input 触发相机或相册选择) -->
      <input ref="imageInput" type="file" accept="image/*" style="display:none" @change="handleImageSelection" />

      <i class="icon-image icon el-icon-circle-plus" @click="triggerImagePicker"></i>
      <i class="icon-send icon el-icon-s-promotion" @click="sendMessage"></i>
    </div>

    <!-- 图片预览模态框 -->
    <div v-if="imageModalVisible" class="image-modal" @click="closeImageModal">
      <img :src="currentImageUrl" class="modal-image" @error="handleImageError($event, 'chat')" />
    </div>
  </div>
</template>

<script>
import HeaderComponent from "../commonComponent/Header.vue";

export default {
  components: {
    HeaderComponent
  },
  data() {
    return {
      roomId: this.$route.params.roomid,
      roomName: "",
      userinfo: JSON.parse(window.sessionStorage.getItem('userinfo')),
      baseApi: "api/v1/chatMessage/",
      marqueeText: "在在線客服页面中 > 輸入vip > 自動為你開通",
      messages: [], // 所有消息
      loadedMessages: [], // 当前加载在页面上展示的消息
      currentPage: 1, // 当前分页页码
      pageSize: 15, // 每页要加载的消息数
      totalMessages: 0, // 假设总消息数
      inputMessage: "", // 绑定文本输入框
      imageModalVisible: false, // 控制图片模态框显示
      currentImageUrl: "", // 存储当前用户点击的图片URL
      showScrollToBottomButton: false, // 控制滚动到底按钮显示
      isLoadingHistory: false, // 是否正在加载历史消息
      noMoreMessages: false, // 是否没有更多历史消息
    };
  },
  computed: {
    paginatedMessages() {
      return this.loadedMessages;
    }
  },
  mounted() {
    this.getMessages(); // 初始化加载消息
    // 监听软键盘弹起事件（移动端优化）
    window.addEventListener('resize', this.adjustForKeyboard);
  },

  methods: {
    // 获取消息 (包括首次加载)
    getMessages() {
      // 防止重复加载
      if (this.isLoadingHistory || this.noMoreMessages) return;

      this.isLoadingHistory = true; // 显示“加载中”
      this.$loading.show();

      // 模拟从API获取数据
      this.$get(this.baseApi, {
        roomId: this.roomId,
        pageNum: this.currentPage,
        pageSize: this.pageSize
      }).then(res => {
        const data = res.body;
        this.roomName = data.roomName;
        const newMessages = data.messageList;
        const total = data.total;

        if (newMessages.length) {
          const chatContent = this.$refs.chatContent;
          const currentScrollHeight = chatContent.scrollHeight;

          // 将新消息存储到页面上，并更新当前页数
          this.loadedMessages = [...newMessages, ...this.loadedMessages];
          this.totalMessages = total;
          this.currentPage++;

          // 滚动调整，保持用户滚动位置
          this.$nextTick(() => {
            const newScrollHeight = chatContent.scrollHeight;
            chatContent.scrollTop = newScrollHeight - currentScrollHeight;
            this.isLoadingHistory = false; // 加载完成
          });
        } else {
          // 如果没有新消息，则设置为 "没有更多消息"
          this.noMoreMessages = true;
          this.isLoadingHistory = false;
        }

        this.$loading.hide();
      }).catch(err => {
        this.$msgError(err.message);
        this.$loading.hide();
      });
    },

    adjustForKeyboard() {
      const chatContent = this.$refs.chatContent;
      const activeElement = document.activeElement;

      // 检查是否有输入框打开
      if (activeElement && activeElement.tagName === 'INPUT') {
        setTimeout(() => {
          chatContent.scrollTo({
            top: chatContent.scrollHeight,
            behavior: 'smooth',
          });
        }, 300); // 延迟滚动，确保 DOM 更新完成
      }
    },

    // 处理滚动事件
    handleScroll() {
      const chatContent = this.$refs.chatContent;

      // 当滚动到顶部时加载更多历史消息
      if (chatContent.scrollTop === 0 && !this.isLoadingHistory && !this.noMoreMessages) {
        this.loadMoreMessages();
      }

      // 控制“回到最新消息”按钮的显示，当用户不在底部时显示
      const isNearBottom = chatContent.scrollHeight - chatContent.scrollTop <= chatContent.clientHeight + 100;
      this.showScrollToBottomButton = !isNearBottom;
    },

    // 加载更多历史消息
    loadMoreMessages() {
      this.getMessages(); // 调用 getMessages 来请求下一页历史消息
    },

    // 发送消息 (文字)
    sendMessage() {
      if (this.inputMessage.trim()) {
        const newMessage = {
          msgType: "text",
          nickname: this.userinfo.nickname,
          avatar: this.userinfo.avatar,
          content: this.inputMessage
        };

        this.messages.push(newMessage);
        this.loadedMessages.push(newMessage);
        this.inputMessage = "";

        // 调用后端 API 发送消息
        this.$post(this.baseApi, {
          roomId: this.roomId, message: newMessage
        }).then(res => {
          if (res.body != '') {
            this.$customModal.confirm({
              title: '提醒',
              content: res.body.content,
              showCancel: false,  // 是否展示取消按钮
              imgUrl: "", // 是否有图片
              onOK: () => { },
              onCancel: () => {
              },
            });
          }
          this.$nextTick(this.scrollToBottom); // 发送后滚动到底部
        }).catch(err => {
          this.$msgError(err.message);
        });
      } else {
        this.$msgError("请输入内容");
      }
    },

    // 上传图片
    handleImageSelection(event) {
      const file = event.target.files[0]; // 获取用户选择的文件
      if (file) {
        this.uploadImage(file); // 上传用户选择的图片
      }
    },

    // 上传图片到服务器
    uploadImage(file) {
      this.$loading.show()
      this.$uploadFileApi('api/v1/uploadFile/', file, {}).then(res => {
        this.sendImageMessage(res.body); // 提交图片上传后的URL作为消息
      }).catch(err => {
        this.$loading.hide();
        this.$msgError(err.message);
      });
    },

    // 发送图片消息
    sendImageMessage(imageUrl) {
      const newImageMessage = {
        msgType: "image",
        nickname: this.userinfo.nickname,
        avatar: this.userinfo.avatar,
        content: imageUrl
      };

      this.loadedMessages.push(newImageMessage);

      this.$nextTick(this.scrollToBottom);

      // 调用后端 API 发送图片消息
      this.$post(this.baseApi, {
        roomId: this.roomId, message: newImageMessage
      }).then(() => {
        this.$nextTick(this.scrollToBottom);
        this.$loading.hide();
      }).catch(err => {
        this.$loading.hide();
        this.$msgError(err.message);
      });
    },

    // 触发图片选择对话框
    triggerImagePicker() {
      this.$refs.imageInput.click();
    },

    // 查看大图
    viewImage(imageUrl) {
      this.currentImageUrl = imageUrl;
      this.imageModalVisible = true;
    },

    // 关闭图片预览模态框
    closeImageModal() {
      this.imageModalVisible = false;
      this.currentImageUrl = "";
    },

    // 滚动到底部
    scrollToBottom() {
      const chatContent = this.$refs.chatContent;
      chatContent.scrollTop = chatContent.scrollHeight;
    },

    // 回到最新消息
    scrollToLatestWithAnimation() {
      const chatContent = this.$refs.chatContent;
      chatContent.scrollTo({
        top: chatContent.scrollHeight,
        behavior: 'smooth'
      });
      this.showScrollToBottomButton = false;
    },

    // 图片链接错误时
    handleImageError(event, type) {
      event.target.src = require('@/assets/images/error.jpg');
    },
  },

  beforeDestroy() {
    // 页面离开或组件即将被销毁 在这里执行一些清理操作
    this.$deleteApi('api/v1/roomLink/', { roomId: this.roomId }).then(res => { }, err => { })
  },

  destroyed() {
    // 页面已离开或组件已经被销毁
  },
};
</script>

<style scoped>
html,
body {
  height: 100%;
  margin: 0;
  overflow: hidden;
}

.chat-page {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: #f5f5f5;
}

/* 顶部固定的头部 */
.header-fixed {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  background-color: white;
  border-bottom: 1px solid #ddd;
}

.chat-content {
  flex: 1;
  padding: 10px;
  padding-bottom: 70px;
  /* 确保内容不会被输入框遮挡 */
  margin-top: 105px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  gap: 10px;
  scroll-padding-bottom: 70px;
  /* 滚动时的底部偏移 */
  background: azure;
}

/* 消息外部容器样式 */
.message {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

/* 左侧消息样式 */
.message-left {
  flex-direction: row;
  justify-content: flex-start;
}

/* 右侧消息样式 */
.message-right {
  flex-direction: row-reverse;
  justify-content: flex-start;
}

/* 用户头像样式 */
.avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid #ddd;
  object-fit: cover;
}

/* 文本消息气泡样式 */
.text-bubble {
  max-width: 45%;
  padding: 10px 15px;
  font-size: 14px;
  line-height: 1.5;
  word-break: break-word;
  border-radius: 10px;
  background-color: #e9e9e9;
  color: #333;
}

/* 左右消息样式区分 (颜色调整) */
.message-right .text-bubble {
  background-color: #006eff;
  color: white;
}

/* 图片消息气泡样式 */
.image-bubble img {
  max-width: 150px;
  /* 图片最大宽度限制 */
  max-height: 150px;
  /* 图片最大高度限制 */
  border-radius: 10px;
  object-fit: contain;
  /* 不拉伸图片，保持比例 */
  cursor: pointer;
  /* border: 1px solid #ddd; */
  display: inline-block;
}

/* 如果某些图片宽高较小，则自然显示 */
.image-bubble img.small-image {
  max-width: 100%;
  /* 尽量按实际尺寸显示 */
  max-height: 100%;
  /* 所需大小 */
  border-radius: 5px;
  /* 统一风格 */
}

/* 加载提示样式 */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.loading,
.no-more-messages {
  font-size: 14px;
  color: #999;
}

/* 滚动到底按钮 */
.scroll-to-bottom {
  position: fixed;
  right: 20px;
  bottom: 80px;
  background-color: #17a3ff;
  color: white;
  padding: 10px 15px;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

/* 输入框固定在底部样式 */
.chat-input {
  display: flex;
  padding: 10px;
  background-color: white;
  border-top: 1px solid #ddd;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
  height: 60px;
  /* 固定高度，与内容底部匹配 */
  box-sizing: border-box;
}

.chat-input input[type="text"] {
  flex: 1;
  padding: 10px;
  border-radius: 20px;
  border: 1px solid #ddd;
  font-size: 14px;
  color: #333;
}

.chat-input input[type="text"]::placeholder {
  color: #aaa;
}

.icon-send,
.icon-image {
  padding: 8px 12px;
  font-size: 24px;
  color: white;
  margin-left: 10px;
  border-radius: 5px;
  cursor: pointer;
}

.icon-send {
  background-color: #006eff;
}

.icon-image {
  background-color: orange;
}

/* 图片查看模态框样式 */
.image-modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 999;
}

.modal-image {
  max-width: 90%;
  max-height: 90%;
  border-radius: 10px;
}

/* 响应式调整 */
@media (max-width: 768px) {
  .text-bubble {
    max-width: 37%;
    font-size: 13px;
  }

  .image-bubble img {
    max-width: 120px;
    max-height: 120px;
  }

  .chat-input input[type="text"] {
    font-size: 12px;
  }
}
</style>
