<template>
    <svg :class="customClass" aria-hidden="true">
        <use :xlink:href="`#${name}`"></use>
    </svg>
</template>

<script>
export default {
    name: "SvgIcon",
    props: {
        name: {
            type: String,
            required: true, // 传入 SVG Symbol 的 ID
        },
        customClass: {
            type: String, // 传入自定义类名，以便给图标额外样式
            default: "",
        },
    },
};
</script>

<style scoped>
svg {
    width: 1em;
    height: 1em;
    fill: currentColor;
    /* Ensures the color can be controlled by text color */
    stroke: currentColor;
    /* Controls stroke color as well */
}
</style>