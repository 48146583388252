<template>
    <div class="container">
        <HeaderComponent title="Actress" :showBack="true" />

        <div style="width: 98%; margin: auto;">
            <!-- 顶部分页组件 -->
            <Pagination :totalItems="totalCount" :pageSize="pageSize" :currentPage="currentPage"
                @page-change="handlePageChange" />

            <div class="detailBox">
                <imageList :allList="allList" />
            </div>

            <!-- 底部分页组件 -->
            <Pagination :totalItems="totalCount" :pageSize="pageSize" :currentPage="currentPage"
                @page-change="handlePageChange" />
        </div>

        <Footer></Footer>

        <!-- 回到顶部 -->
        <scrollTop :scrollDom="'.container'"></scrollTop>
    </div>
</template>

<script>
import HeaderComponent from "../commonComponent/Header.vue";
import Search from "../commonComponent/Search.vue";
import imageList from "../commonComponent/imageListComponent.vue";
import Footer from "../commonComponent/Footer.vue";
import scrollTop from "../commonComponent/Scroll.vue";
import Pagination from "../commonComponent/Pagination.vue";

export default {
    components: {
        HeaderComponent,
        imageList,
        Footer,
        scrollTop,
        Pagination,
        Search
    },
    data() {
        return {
            actorName: null,       // 当前类别
            allList: [],          // 数据列表
            totalCount: 0,        // 总数据量
            pageSize: 20,         // 每页显示的条数
            currentPage: 1,       // 当前页码
            searchKeyword: null,  // 搜索关键字
        };
    },
    created() {
        this.initializePage(); // 初始化页面并加载数据
    },
    methods: {
        // 初始化页面
        initializePage() {
            let name = this.$route.params.name || window.sessionStorage.getItem("actorName");
            if (name) {
                window.sessionStorage.setItem("actorName", name);
            }
            this.actorName = name;
            let savedPage = window.sessionStorage.getItem("actorPage" + name);
            this.currentPage = savedPage ? Number(savedPage) : 1;
            this.getAllFunc(this.actorName, this.currentPage); // 加载数据
        },

        // 获取数据
        getAllFunc(name, page) {
            this.$loading.show();
            window.sessionStorage.setItem("actorPage" + name, page); // 保存当前页码
            this.$post("api/v1/getActorData/", {
                name,
                pageNum: page,
            }).then(
                (res) => {
                    this.allList = res.body.data;
                    this.totalCount = res.body.count;
                    this.$loading.hide();
                },
                (err) => {
                    this.$msgError(err.message);
                    this.$loading.hide();
                }
            );
        },

        // 处理分页变化时的逻辑
        handlePageChange(page) {
            this.currentPage = page;                // 更新当前页码
            this.getAllFunc(this.actorName, page);   // 获取新的数据
        },
    }
};
</script>

<style scoped></style>
