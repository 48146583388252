<template>
    <div class="container">
        <HeaderComponent title="我的收藏" :showBack="true" />
        <div class="content">
            <!-- 你可以在这里展示收藏的内容 -->
            <p>这里是我的收藏页面内容。</p>
        </div>
    </div>
</template>

<script>
import HeaderComponent from "../commonComponent/Header.vue";
export default {
    components: {
        HeaderComponent
    },
}
</script>

<style scoped>
.icon.back {
    font-size: 22px;
    margin-right: 10px;
    cursor: pointer;
    color: #333;
}

.content {
    padding: 20px;
    font-size: 16px;
    color: #666;
}
</style>