<template>
  <div id="app">
    <!-- 动态路由组件渲染 -->
    <router-view />

    <!-- Tabbar 组件 -->
    <Tabbar :tabPosition="tabPosition" :tabItems="tabItems" :activeTab="activeTab" @update-tab="changeRoute"
      v-if="requiresTabbar" />
  </div>
</template>

<script>
// 引入页面组件和Tabbar
import Tabbar from './components/tabbarPage.vue';

export default {
  name: 'App',
  components: {
    Tabbar,
  },
  data() {
    return {
      tabPosition: 'bottom',  // Tabbar 位于底部
      tabItems: [],           // Tabbar 列表
    };
  },
  computed: {
    // 判断当前路由是否需要展示Tabbar
    requiresTabbar() {
      return this.$route.meta.requiresTabbar === true;
    },
    // 根据当前路由动态计算激活的 Tab 项
    activeTab() {
      const currentRoute = this.$route.path;
      const activeTabItem = this.tabItems.find(item => item.route === currentRoute);
      return activeTabItem ? activeTabItem.name : ""; // 返回对应的 tab 名称
    },
  },
  mounted() {
    this.$loading.show();
    this.fetchTabItems();
  },
  methods: {
    fetchTabItems() {
      this.$post('api/v1/tabbar/', {}).then((res) => {
        this.tabItems = res.body.data;
        window.sessionStorage.setItem('uploadType', res.body.uploadType)
      }, (err) => {
        this.$msgError(err.message);
      });
      this.$loading.hide();
    },

    changeRoute(newPage) {
      // 找到与点击的 Tab 对应的路由路径并进行跳转
      const selectedTab = this.tabItems.find(item => item.name === newPage);
      if (selectedTab) {
        this.$router.push(selectedTab.route);
      }
    },
  },
};
</script>

<style>
body {
  font-family: Arial, serif;
  margin: 0;
  padding: 0;
  background-color: #eeeef5;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
</style>
