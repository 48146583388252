<template>
    <div class="marquee-container">
        <div class="marquee-img">
            <img src="@/assets/images/icons8.gif" />
        </div>
        <div class="marquee-wrapper">
            <div class="marquee-content" :style="{ transform: `translateX(${offset}px)` }">
                {{ marqueeText }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        marqueeText: {
            type: String,
            default: "警告：账号禁止共享 違者將封禁賬號處理",
        },
        speed: {
            type: Number,
            default: 1.5, // 默认速度为 1.5（每帧 1.5px）
        },
    },
    data() {
        return {
            offset: 0, // 初始偏移量
            contentWidth: 0, // 文字内容宽度
            containerWidth: 0, // 容器宽度
            animationFrame: null, // 动画帧 ID
            resizeObserver: null, // ResizeObserver
        };
    },
    mounted() {
        this.initDimensions();
        this.startMarquee(); // 启动走马灯效果

        // 我们使用 ResizeObserver 监听 DOM 更新
        this.resizeObserver = new ResizeObserver(this.updateDimensions);
        this.resizeObserver.observe(this.$el.querySelector(".marquee-wrapper"));

        window.addEventListener("resize", this.updateDimensions);
    },
    beforeDestroy() {
        cancelAnimationFrame(this.animationFrame);

        // 停止观察 DOM 变化
        if (this.resizeObserver) {
            this.resizeObserver.disconnect();
        }
        window.removeEventListener("resize", this.updateDimensions); // 移除 resize 事件监听
    },
    methods: {
        initDimensions() {
            // 延时确保 DOM 渲染完毕后计算宽度，确保移动端精确
            setTimeout(() => {
                this.updateDimensions();
            }, 100);
        },
        updateDimensions() {
            this.$nextTick(() => {
                const contentElement = this.$el.querySelector(".marquee-content");
                const containerElement = this.$el.querySelector(".marquee-wrapper");

                // 确保取到准确的宽度
                this.contentWidth = contentElement ? contentElement.offsetWidth : 0;
                this.containerWidth = containerElement ? containerElement.offsetWidth : 0;

                // 初始化 offset 值，以确保会从屏幕右边开始进入
                this.offset = this.containerWidth;
            });
        },
        startMarquee() {
            const step = () => {
                // 根据传入的 speed 来控制步长，speed 越大滚动越快
                this.offset -= this.speed;

                // 当文字完全滚出容器视图时，重置到右端
                if (this.offset + this.contentWidth <= 0) {
                    this.offset = this.containerWidth;
                }

                // 请求下一帧动画
                this.animationFrame = requestAnimationFrame(step);
            };

            // 启动动画
            step();
        },
    },
};
</script>

<style scoped>
.marquee-container {
    overflow: hidden;
    white-space: nowrap;
    background-color: #f0f0f0;
    padding: 0 10px;
    box-sizing: border-box;
    /* margin: 15px auto 0 auto; */
    display: flex;
    align-items: center;
}

.marquee-img>img {
    vertical-align: middle;
    border-style: none;
    width: 30px;
    height: auto;
    /* 确保图像在不同设备正确显示 */
}

.marquee-wrapper {
    flex-grow: 1;
    overflow: hidden;
    position: relative;
    margin-left: 10px;
    max-width: 100%;
    /* 确保在移动端不超出边界 */
}

.marquee-content {
    white-space: nowrap;
    color: #333;
    font-size: 14px;
    display: inline-block;
    /* 让内容适应其父元素宽度 */
}
</style>
