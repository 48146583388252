<template>
    <div class="title-wrapper" @click="onClick">
        <h4 class="title-text">{{ title }}</h4>
        <div class="line"></div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: '全部活動',
        },
    },
    methods: {
        onClick() {
            this.$emit('handleFunc', this.title);
        },
    },
};
</script>

<style scoped>
.title-wrapper {
    display: flex;
    align-items: center;
}

.title-text {
    font-size: 14px;
    font-weight: bold;
    color: #333;
    margin-right: 10px;
    /* 给文字和线之间留一点间距 */
    white-space: nowrap;
    /* 保证文字不会换行 */
}

.line {
    flex: 1;
    /* 让线条占据剩余空间 */
    height: 1px;
    background-color: #d8d8d8;
}
</style>
