// loading-plugin.js
import LoadingComponent from '../components/commonComponent/Loading.vue';

const Loading = {
  install(Vue, options) {
    const VueLoading = Vue.extend(LoadingComponent);
    const vm = new VueLoading();
    vm.$mount(document.createElement('div'));
    document.body.appendChild(vm.$el);

    Vue.prototype.$loading = {
      show: (message = '正在加載中...') => {
        vm.message = message;  // 传递参数给组件
        vm.show(message);  // 调用 `show` 方法显示 loading
      },
      hide: () => vm.hide(),
    };
  }
};

export default Loading;
