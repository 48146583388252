import CustomModal from '../components/commonComponent/CustomModal.vue';

const modalPlugin = {
    install(Vue) {
        // 创建一个 Vue 实例，嵌入到全局 DOM 中
        const ModalConstructor = Vue.extend(CustomModal);
        const instance = new ModalConstructor();
        instance.$mount(document.createElement('div'));
        document.body.appendChild(instance.$el);

        // 通过 Vue.prototype 将 confirm 方法挂载到 $customModal 中
        Vue.prototype.$customModal = {
            confirm({ title, content, onOK, onCancel, showCancel, imgUrl }) {
                instance.show({ title, content, onOK, onCancel, showCancel, imgUrl });
            }
        };
    }
};

export default modalPlugin;
