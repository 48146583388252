<template>
    <div class="footer">{{ footerStr }}</div>
</template>

<script>
export default {
    props: {
        footerStr: {
            type: String,
            default: "暫無更多"
        },
    },
};
</script>

<style scoped>
.footer {
    margin: 50px 0 100px 0;
    text-align: center;
    font-size: 16px;
}
</style>