<!-- 
Component Use Test
this.$loading.show()
this.$loading.hide() 
-->

<template>
    <div class="cus-loading-overlay" v-show="isActive">
        <div class="cus-loading-spinner"></div>
        <div class="cus-loading-text">{{ text }}</div>
    </div>
</template>

<script>
export default {
    name: "Loading",
    data() {
        return {
            isActive: false,
            text: '正在加載中...' // 默认文本
        };
    },
    methods: {
        show(message) {
            this.text = message || '正在加載中...'; // 传入自定义文本或者使用默认文本
            this.isActive = true;
        },
        hide() {
            this.isActive = false;
        }
    }
};
</script>

<style scoped>
.cus-loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.cus-loading-spinner {
    border: 4px solid transparent;
    border-radius: 50%;
    border-top: 4px solid var(--loading-bg);
    /* 浅粉色 */
    width: 40px;
    height: 40px;
    -webkit-animation: spin 1s linear infinite;
    /* Chrome, Safari, Opera */
    animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.cus-loading-text {
    margin-top: 20px;
    color: #fff;
    font-size: 16px;
}
</style>