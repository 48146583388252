<template>
    <div class="pagination-container">
        <button class="pagination-button" :disabled="currentPage === 1" @click="prevPage">
            上一頁
        </button>
        <span class="page-indicator">
            第 {{ currentPage }} 頁 / 共 {{ totalPages }} 頁
        </span>
        <button class="pagination-button" :disabled="currentPage === totalPages" @click="nextPage">
            下一頁
        </button>
    </div>
</template>

<script>
export default {
    props: {
        totalItems: {
            type: Number,
            required: true
        },
        pageSize: {
            type: Number,
            default: 10
        },
        currentPage: {
            type: Number,
            required: true,    // 接收父组件传递的页码
        },
    },
    computed: {
        // 计算总页数
        totalPages() {
            return Math.ceil(this.totalItems / this.pageSize);
        }
    },
    methods: {
        // 跳转到上一页
        prevPage() {
            if (this.currentPage > 1) {
                this.$emit('page-change', this.currentPage - 1);  // 用$emit通知父组件
            }
        },
        // 跳转到下一页
        nextPage() {
            if (this.currentPage < this.totalPages) {
                this.$emit('page-change', this.currentPage + 1);  // 用$emit通知父组件
            }
        }
    }
};
</script>

<style scoped>
/* 样式保持和之前的一致 */
.pagination-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
}

.pagination-button {
    background-color: #f0f0f0;
    border: none;
    margin: 0 10px;
    padding: 10px 15px;
    cursor: pointer;
    font-weight: bold;
    color: #333;
    transition: background-color 0.3s;
}

.pagination-button:disabled {
    background-color: #ddd;
    cursor: not-allowed;
}

.pagination-button:not(:disabled):hover {
    background-color: #d5e0ff;
}

.page-indicator {
    font-size: 16px;
    color: #333;
    font-weight: bold;
}

/* 响应式设计：移动端按钮和页码略缩小 */
@media (max-width: 768px) {
    .pagination-button {
        padding: 8px 12px;
    }

    .page-indicator {
        font-size: 14px;
    }
}
</style>
