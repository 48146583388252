const debug = process.env.VUE_APP_DEBUG === 'true';

// 检查控制台是否被打开
function isConsoleOpen() {
    const threshold = 160;
    const startSize = new Image().height;
    console.log('%cConsole Detection', `font-size:1px; line-height:${startSize}px;`);
    const endSize = new Image().height;
    const isOpenBySize = endSize !== startSize || window.outerWidth - window.innerWidth > threshold;

    const startTime = performance.now();
    debugger;
    const endTime = performance.now();
    const isOpenByPerformance = endTime - startTime > 100;

    return isOpenBySize || isOpenByPerformance;
}

// 锁定页面并禁止所有交互、加载和请求
function lockPage() {
    document.body.innerHTML = '';

    const lockDiv = document.createElement('div');
    lockDiv.innerText = '由于检测到非法操作，页面已锁定！';
    lockDiv.style.position = 'fixed';
    lockDiv.style.top = '0';
    lockDiv.style.left = '0';
    lockDiv.style.width = '100%';
    lockDiv.style.height = '100%';
    lockDiv.style.backgroundColor = 'black';
    lockDiv.style.color = 'white';
    lockDiv.style.display = 'flex';
    lockDiv.style.justifyContent = 'center';
    lockDiv.style.alignItems = 'center';
    lockDiv.style.fontSize = '24px';
    lockDiv.style.zIndex = '99999';
    document.body.appendChild(lockDiv);

    document.body.style.pointerEvents = 'none';
    document.body.style.userSelect = 'none';

    // 停止页面所有资源加载
    if (typeof window.stop === 'function') {
        window.stop();
    } else if (document.execCommand) {
        document.execCommand('Stop', false);
    }

    disableNetworkRequests();

    // 防止刷新操作
    preventPageReload();

    // 记录非法操作状态到 LocalStorage
    sessionStorage.setItem('illegalOperation', 'true');
}

// 禁止所有后续网络请求
function disableNetworkRequests() {
    // 禁止 fetch
    window.fetch = function () {
        return Promise.reject('非法操作：请求被禁止！');
    };

    // 禁止 XMLHttpRequest
    XMLHttpRequest.prototype.open = function () {
        throw new Error('非法操作：已拦截请求！');
    };

    // 禁止 WebSocket
    window.WebSocket = function () {
        throw new Error('非法操作：已拦截 WebSocket！');
    };
}

// 防止页面刷新或重载
function preventPageReload() {
    window.onbeforeunload = function (e) {
        e.preventDefault();
        e.returnValue = '';
    };

    history.pushState(null, null, document.URL);
    window.addEventListener('popstate', () => {
        history.pushState(null, null, document.URL);
    });
}

// 初始化非法操作的检测状态
function checkIllegalOperationOnPageLoad() {
    const illegalOperationDetected = sessionStorage.getItem('illegalOperation');
    if (illegalOperationDetected === 'true') {
        lockPage(); // 如果页面之前已经检测到非法操作，直接锁屏并禁止请求
    }
}

// 禁止右键菜单
function disableRightClick() {
    document.addEventListener('contextmenu', (e) => e.preventDefault());
}

// 禁止所有快捷键
function disableShortcuts() {
    document.addEventListener(
        'keydown',
        (event) => {
            if (
                event.keyCode === 123 || // F12
                (event.ctrlKey && event.shiftKey && event.keyCode === 73) || // Ctrl+Shift+I
                (event.ctrlKey && event.shiftKey && event.keyCode === 74) || // Ctrl+Shift+J
                (event.ctrlKey && event.keyCode === 85) || // Ctrl+U
                (event.ctrlKey && event.shiftKey && event.keyCode === 67) // Ctrl+Shift+C
            ) {
                event.preventDefault();
                event.stopPropagation();
                console.log('已阻止快捷键操作！');
            }
        },
        true
    );
}

// 自动检测控制台
function monitorConsole() {
    setInterval(() => {
        if (isConsoleOpen()) {
            lockPage();
        }
    }, 500); // 每半秒检测一次
}

// 定时触发 debugger
function forceDebugger() {
    if (!debug) {
        setTimeout(() => {
            if (isConsoleOpen()) {
                lockPage();
            }
            forceDebugger();
        }, 500); // 递归检测
    }
}

// 禁用图片交互
function disableImageInteractions() {
    const setDraggableFalse = (img) => {
        img.setAttribute('draggable', 'false');
        img.addEventListener('contextmenu', (event) => event.preventDefault());
    };

    const imgs = document.querySelectorAll('img');
    imgs.forEach(setDraggableFalse);

    const observer = new MutationObserver((mutationsList) => {
        mutationsList.forEach((mutation) => {
            mutation.addedNodes.forEach((node) => {
                if (node.tagName === 'IMG') {
                    setDraggableFalse(node);
                }
                if (node.nodeType === Node.ELEMENT_NODE) {
                    const imgs = node.querySelectorAll('img');
                    imgs.forEach(setDraggableFalse);
                }
            });
        });
    });

    observer.observe(document.body, {
        childList: true,
        subtree: true,
    });
}

// 初始化所有安全功能
function initSafetyFeatures() {
    checkIllegalOperationOnPageLoad(); // 检测前次非法操作状态
    if (!debug) {
        disableRightClick(); // 禁用右键菜单
        disableShortcuts(); // 禁用快捷键
        document.addEventListener('DOMContentLoaded', disableImageInteractions); // 禁用图片交互
        monitorConsole(); // 自动检测控制台
        forceDebugger(); // 定时调试检测
    } else {
        console.log('调试模式已开启');
    }
}

// 导出初始化功能
export { initSafetyFeatures };
