<template>
    <div class="container">
        <!-- 头部组件 -->
        <HeaderComponent title="充值页面" :showBack="true" />

        <div class="content">
            <!-- 余额卡片 -->
            <div class="card-balance">
                <div class="balance-container">
                    <h3>我的余额</h3>
                    <p class="balance">¥ <span>{{ balance }}</span></p>
                    <p class="card-number">{{ cardNumber }}</p>
                </div>
                <div class="details-container">
                    <div class="details">
                        <div>可用本金 ¥ {{ principal }}</div>
                        <div>赠送金额 ¥ {{ giftAmount }}</div>
                        <div>冻结金额 ¥ {{ frozenAmount }}</div>
                    </div>
                </div>
            </div>

            <!-- 充值金额输入 -->
            <div class="input-section">
                <label>￥</label>
                <input type="number" v-model="amount" placeholder="请输入充值金额" />
            </div>

            <!-- 支付方式 -->
            <div class="payment-methods">
                <div v-for="(method, index) in methods" :key="index" class="method"
                    :class="{ selected: selectedMethod === index }" @click="selectMethod(index)">
                    <img :src="method.icon" alt="支付方式图标" />
                    <span>{{ method.name }}</span>
                    <span class="radio" /> <!-- 圆形选中标识 -->
                </div>
            </div>

            <!-- 充值协议 -->
            <p class="agreement">
                <span>点击充值即表示你已同意</span>
                <a href="#agreement">《充值协议》</a>
            </p>

            <!-- 充值按钮 -->
            <button class="recharge-btn" @click="recharge">立即充值</button>
        </div>
    </div>
</template>


<script>
import HeaderComponent from "../commonComponent/Header.vue";

export default {
    name: "Recharge",
    components: {
        HeaderComponent,
    },
    data() {
        return {
            balance: 129.29, // 当前余额
            principal: 129.0, // 可用金额
            giftAmount: 0.29, // 赠送金额
            frozenAmount: 1000.0, // 冻结金额
            cardNumber: "6122 **** **** 60606", // 卡号

            amount: "", // 充值金额
            selectedMethod: 0, // 默认选中支付宝
            methods: [
                {
                    name: "支付宝",
                    icon: "https://kanku-static.s3.amazonaws.com/kanku-media/cbce6604-a724-4716-94cd-065510af868d",
                },
                {
                    name: "微信支付",
                    icon: "https://kanku-static.s3.amazonaws.com/kanku-media/112c3a19-f9ae-41e4-bb26-84b353fc8868",
                },
                {
                    name: "银行卡支付",
                    icon: "https://kanku-static.s3.amazonaws.com/kanku-media/2cf30cc4-3889-49a1-a55e-ca40d84d9992",
                },
            ],
        };
    },
    methods: {
        selectMethod(index) {
            this.selectedMethod = index;
        },
        recharge() {
            if (!this.amount || this.amount <= 0) {
                alert("请输入有效的充值金额");
                return;
            }
            alert(
                `您选择了充值金额：¥${this.amount}，支付方式：${this.methods[this.selectedMethod].name}`
            );
        },
    },
};
</script>


<style scoped>
/* 卡片样式 */
.card-balance {
    width: 90%;
    margin: 10px auto;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 12px;
    color: white;

    /* 多背景叠加 */
    background:
        repeating-linear-gradient(45deg, #3d7eff, #31c9e8),
        url("https://kanku-static.s3.amazonaws.com/kanku-media/be63a18b-93be-4fb9-bbfd-942f7dc993b5") no-repeat center;
    background-size: cover;
    background-position: bottom;
    background-blend-mode: overlay;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}



.card-balance h3 {
    font-size: 18px;
    margin-bottom: 10px;
}

.card-balance .balance {
    font-size: 32px;
    font-weight: bold;
    margin: 10px 0;
}

.card-balance .details {
    text-align: right;
    font-size: 14px;
    color: #dbeeff;
    line-height: 1.8;
}

.card-balance .details div {
    margin: 3px 0;
}

.card-balance .card-number {
    margin-top: 10px;
    text-align: left;
    font-size: 0.9rem;
    color: #bbdefb;
}

/* 布局设置 */
.card-balance .balance-container {
    flex: 1;
}

.card-balance .details-container {
    flex: 1;
    text-align: right;
}

/* 输入框样式 */
.input-section {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border-radius: 12px;
    margin: 20px auto;
    width: 90%;
}

.input-section label {
    color: #999;
    font-size: 18px;
    margin-right: 10px;
}

.input-section input {
    flex: 1;
    border: none;
    font-size: 16px;
    color: #333;
    outline: none;
    background: none;
}

/* 支付方式样式 */
.payment-methods {
    padding: 0 3px;
}

.method {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 15px;
    border-radius: 8px;
    margin-bottom: 10px;
    cursor: pointer;
    position: relative;
}

.method img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
}

.method span {
    flex: 1;
    font-size: 16px;
    color: #333;
}

.method.selected {
    border-color: #4a90e2;
}

.method .radio {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    position: absolute;
    right: 15px;
}

.method.selected .radio {
    border-color: #4a90e2;
    background: #4a90e2;
}

/* 协议样式 */
.agreement {
    text-align: center;
    font-size: 14px;
    color: #999;
    margin: 20px 0;
}

.agreement a {
    text-decoration: underline;
    color: #4a90e2;
}

/* 充值按钮 */
.recharge-btn {
    width: 70%;
    padding: 12px 0;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    border: none;
    border-radius: 8px;
    margin: 20px auto;
    display: block;
    color: white;
    background: linear-gradient(to right, #4a90e2, #6dbfff);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    cursor: pointer;
}
</style>
