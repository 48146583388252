<template>
    <div class="container">
        <HeaderComponent title="個人中心" :showBack="false" />

        <!-- 用户信息 -->
        <div class="profile">
            <img class="avatar" :src="userAvatar" alt="avatar" />
            <div class="info">
                <div class="name">{{ userinfo.nickname }}</div>
                <div class="id" @click="$copyText(userinfo.coupon)">
                    優惠碼: {{ userinfo.coupon }} <i class="icon el-icon-copy-document"></i>
                </div>
                <div class="id">會員時間（S）: {{ userinfo.vipStart }}</div>
                <div class="id">會員時間（E）: {{ userinfo.vipEnd }}</div>
            </div>
            <div class="qr-code"><i class="icon el-icon-qrcode"></i></div>
        </div>

        <!-- 会员权益显示 -->
        <VipComponent />


        <!-- 菜单项 -->
        <div class="menu">
            <div class="menu-item" v-for="item, index in menuList" :key="index" @click="navigateTo(item)">
                <div class="menu-content">
                    <i :class="['icon', item.class]"></i>
                    <span>{{ item.name }}</span>
                </div>
                <div class="arrow"><i class="icon el-icon-arrow-right"></i></div>
            </div>
        </div>

    </div>
</template>

<script>
import HeaderComponent from "../components/commonComponent/Header.vue";
import VipComponent from "./commonComponent/VipComponent.vue";
export default {
    components: {
        HeaderComponent,
        VipComponent
    },
    data() {
        return {
            userAvatar: "https://kanku-static.s3.amazonaws.com/kanku-media/a52243e1-c102-47b8-bbd2-f9a598995424",
            userinfo: {},
            menuList: []
        }
    },
    mounted() {
        const hasToken = sessionStorage.getItem('token');
        if (!hasToken) {
            this.$router.push({ path: "/login" });
        } else {
            var userinfo = window.sessionStorage.getItem('userinfo');
            var menuList = window.sessionStorage.getItem('menuList');
            if (userinfo && menuList) {
                this.userinfo = JSON.parse(userinfo);
                this.menuList = JSON.parse(menuList);
            } else {
                this.$loading.show();
                this.getUserDetailApi();
            }
        }
    },
    methods: {
        getUserDetailApi() {
            this.$post('api/v1/userDetail/', {}).then(res => {
                this.userinfo = res.body.data;
                this.menuList = res.body.handleList;
                this.$loading.hide();
                window.sessionStorage.setItem('userinfo', JSON.stringify(res.body.data));
                window.sessionStorage.setItem('menuList', JSON.stringify(res.body.handleList));
            }, err => {
                this.$loading.hide();
                this.$msgError(err.message);
            });
        },
        // 切换页面的函数
        navigateTo(item) {
            if (item.pathName != "getOutFunc") {
                this.$router.push({
                    name: item.pathName,
                    params: item.params
                });
            } else {
                const actions = {
                    getOutFunc: () => this.getOutFunc(),
                };
                if (actions[item.pathName]) actions[item.pathName]();
            }
        },

        getOutFunc() {
            this.$alert('是否確認退出', '注銷登錄', {
                confirmButtonText: '確認',
                callback: action => {
                    if (action == 'confirm') {
                        window.sessionStorage.clear()
                        window.location.reload()
                    }
                }
            });
        },
    }
};
</script>

<style scoped>
/* 用户信息样式 */
.profile {
    display: flex;
    align-items: center;
    padding: 15px;
    border-radius: 10px;
    border-bottom: 2px solid #f0f0f0;
    width: 100%;
    box-sizing: border-box;
}

.avatar {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-right: 10px;
}

.info {
    flex-grow: 1;
}

.name {
    font-size: 1.2rem;
    font-weight: bold;
    color: #333;
}

.id {
    /* font-size: 0.85rem; */
    color: #999;
    margin-top: 4px;
}

.qr-code {
    color: #999;
    font-size: 1.5rem;
}

/* 菜单列表样式 */
.menu {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 99%;
    padding: 10px;
    margin: 10px auto;
}

.menu-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 15px;
    border-radius: 10px;
    cursor: pointer;
}

.menu-content {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 1rem;
}

.icon {
    font-size: 1.5rem;
    color: #666;
}

.arrow {
    font-size: 1rem;
    color: #999;
}

/* .menu-item:hover {
    background-color: #f0f2f5;
} */

/* 小屏设备优化 */
@media only screen and (max-width: 767px) {
    .profile {
        padding: 10px;
    }

    .menu {
        width: 95%;
    }

    .menu-item {
        padding: 10px;
    }
}
</style>
