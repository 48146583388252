<template>
    <div class="container">
        <HeaderComponent title="首頁" :showBack="false" />
        <carouselPage></carouselPage>

        <Category></Category>

        <CustomImgList isType="free" isSize="8" isTitle="免費放映（更多資源）" :isShowMore="true"></CustomImgList>
        <CustomImgList isType="hot" isSize="15" isTitle="熱門放映（更多資源）" :isShowMore="true"></CustomImgList>

        <Footer></Footer>
        <scrollTop :scrollDom="'.container'"></scrollTop>
    </div>
</template>

<script>
import HeaderComponent from "../components/commonComponent/Header.vue";
import carouselPage from "./commonComponent/Carousel.vue";
import Category from "./commonComponent/Category.vue";
import CustomImgList from "./commonComponent/CustomImgList.vue";
import Footer from "./commonComponent/Footer.vue";
import scrollTop from "./commonComponent/Scroll.vue";

export default {
    components: {
        HeaderComponent,
        carouselPage,
        Category,
        CustomImgList,
        Footer,
        scrollTop
    },
    mounted() {
        if (!window.sessionStorage.getItem("checkNotice")) {
            this.getNoticeApi()
        }
    },
    methods: {
        getNoticeApi() {
            this.$post('api/v1/getNotice/', {}).then((res) => {
                this.$customModal.confirm({
                    title: '提醒',
                    content: res.body.notice,
                    showCancel: false,  // 是否展示取消按钮
                    imgUrl: res.body.src, // 是否有图片
                    onOK: () => {
                        window.sessionStorage.setItem("checkNotice", true)
                    },
                    onCancel: () => {
                    },
                });
            }, (err) => {
                this.$msgError(err.message);
            });
        },
    }
};
</script>