<template>
    <div class="search-container">
        <div class="search-input-wrapper">
            <i class="icon el-icon-search"></i>
            <input type="text" v-model="searchQuery" :placeholder="placeholder">
        </div>
        <button class="search-button" @click="onSearch">
            搜 索
        </button>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            default: '',
        },
        placeholder: {
            type: String,
            default: "請輸入搜索詞"
        }
    },
    data() {
        return {
            searchQuery: this.value, // 绑定到输入框的搜索关键词
        };
    },
    watch: {
        // 确保 prop 的值和本地的数据保持同步
        value(newValue) {
            this.searchQuery = newValue;
        }
    },
    methods: {
        onSearch() {
            // 通过 emit 发送搜索关键词给父组件
            this.$emit('input', this.searchQuery);
            this.$emit('search', this.searchQuery);
        }
    }
};
</script>

<style scoped>
/* 与之前相同的样式，可以保留或修改 */
.search-container {
    display: flex;
    align-items: center;
    border: 2px solid #ccc;
    border-radius: 25px;
    padding: 5px;
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    margin: 10px;
}

.search-input-wrapper {
    display: flex;
    align-items: center;
    flex: 1;
}

.search-input-wrapper input {
    border: none;
    outline: none;
    font-size: 14px;
    flex: 1;
    padding: 5px;
    color: #333;
    background-color: transparent;
}

/* 图标样式 */
.icon {
    font-family: "icomoon";
    /* 你可以使用 iconfont 或自定义字体图标 */
    font-size: 20px;
    color: rgba(0, 0, 0, 0.3);
    margin-right: 5px;
}

/* 搜索按钮样式 */
.search-button {
    background-color: #000;
    color: white;
    border: none;
    border-radius: 20px;
    padding: 8px 16px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-left: 8px;
}

.search-button:hover {
    background-color: #333;
}

@media (max-width: 768px) {
    .search-button {
        padding: 6px 12px;
    }
}
</style>