<template>
    <div :class="['headerBox', headerCss]">
        <div class="back-icon">
            <span v-if="showBack" @click="goBack" style="margin-right: 10px;">
                <i class="icon el-icon-arrow-left"></i>
            </span>
            <span @click="goHome">
                <i class="el-icon-s-home"></i>
            </span>
        </div>
        <div class="title">{{ title }}</div>
        <MarQuee :marqueeText="marqueeText" v-if="showMarQuee"></MarQuee>
    </div>
</template>

<script>
import MarQuee from "./MarQuee.vue"

export default {
    name: 'HeaderComponent',
    components: {
        MarQuee,
    },
    props: {
        title: {
            type: String,
            default: '页面标题' // 默认标题
        },
        showBack: {
            type: Boolean,
            default: false // 是否显示返回按钮
        },
        showMarQuee: {
            type: Boolean,
            default: false // 是否显示走马灯
        },
        marqueeText: {
            type: String,
            default: '輸入 會員/vip 自動為你開通' // 默认标题
        },
        headerCss: {
            type: String,
            default: 'header-container'
        }
    },

    methods: {
        goBack() {
            this.$router.go(-1); // 执行返回上一页的操作
        },
        goHome() {
            this.$router.push("/");
        },
    }
};
</script>

<style scoped>
.headerBox {
    padding: 10px 0;
    border-bottom: 1px solid #eee;
    color: #333;
    margin-bottom: 5px;
    height: 40px;
    line-height: 45px;
    font-size: 20px;
    font-weight: 800;

    /* 透明背景 */
    background-color: rgba(255, 255, 255, 0.6);
    /* 玻璃模糊效果 */
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    /* 添加轻微的阴影（可选） */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header-fixed {
    position: fixed;
    /* 固定头部位置 */
    top: 0;
    left: 0;
    right: 0;
    z-index: 999;
}

/* Header 容器样式 */
.header-container {
    position: sticky;
    /* 保证悬浮在其他元素之上 */
    top: 0;
    z-index: 999;
}

/* 返回按钮样式 */
.back-icon {
    position: absolute;
    left: 10px;
    cursor: pointer;
}

/* 标题样式 */
.title {
    text-align: center;
}
</style>
