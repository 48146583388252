<template>
    <div class="container">
        <HeaderComponent title="發現" :showBack="false" />

        <div style="width: 98%; margin: auto;">
            <Search v-model="searchKeyword" @search="handleSearch" />

            <Pagination :totalItems="totalCount" :pageSize="pageSize" :currentPage="currentPage"
                @page-change="onPageChange" />

            <div class="searchBox" v-loading="loading">
                <div v-for="(items, index) in allList" :key="index" class="item" @click="toActor(items)">
                    <div class="image-container">
                        <img :src="items.src" class="avatar-image" :alt="items.name" @load="handleImageLoad(items)"
                            @error="handleImageError" />
                        <!-- 图片加载时显示加载动画 (加载完成时会消失) -->
                        <div class="loading-spinner" v-if="!items.imageLoaded"></div>
                    </div>
                    <div class="item-name">{{ items.name }}</div>
                    <div>
                        <span v-for="(tag, index) in items.desc" :key="index">
                            <el-tag class="actressTag">{{ tag }}</el-tag>
                        </span>
                    </div>
                </div>
            </div>

            <Pagination :totalItems="totalCount" :pageSize="pageSize" :currentPage="currentPage"
                @page-change="onPageChange" />
        </div>

        <Footer></Footer>
        <scrollTop :scrollDom="'.container'"></scrollTop>
    </div>
</template>

<script>
import HeaderComponent from "../components/commonComponent/Header.vue";
import Search from "../components/commonComponent/Search.vue";
import Footer from "./commonComponent/Footer.vue";
import scrollTop from "./commonComponent/Scroll.vue";
import Pagination from "./commonComponent/Pagination.vue";

export default {
    components: {
        HeaderComponent,
        Footer,
        scrollTop,
        Pagination,
        Search
    },
    data() {
        return {
            allList: [],            // 储存获取到的数据列表
            totalCount: 0,          // 数据总条数，用于分页
            pageSize: 30,           // 每页显示的条数
            currentPage: 1,         // 当前页面
            searchKeyword: "",    // 搜索框的值
            loading: false,         // 是否加载中
            errSrc: ""              // 图片加载失败时的占位符
        };
    },
    mounted() {
        this.fetchData(1); // 页面加载时初始化第一页数据
    },
    methods: {
        fetchData(page) {
            this.loading = true;
            this.$post("api/v1/getAvatarList/", {
                searchVal: this.searchKeyword,
                pageNum: page,
                pageSize: this.pageSize,  // 确保接口能获取分页大小
            }).then(
                (res) => {
                    this.allList = res.body.data.map(item => ({
                        ...item,
                        imageLoaded: false
                    }));
                    this.totalCount = res.body.count; // 更新总条数
                    this.loading = false;
                    this.errSrc = res.body.errSrc
                },
                (err) => {
                    this.$msgError(err.message);
                    this.loading = false;
                }
            );
        },
        handleImageLoad(item) {
            item.imageLoaded = true;
        },
        handleImageError(event) {
            event.target.src = this.errSrc;
        },
        handleSearch(keyword) {
            console.log(keyword);

            this.currentPage = 1; // 搜索时重置为第 1 页
            this.fetchData(this.currentPage); // 搜索时重新获取第一页数据
        },
        onPageChange(page) {
            this.currentPage = page; // 更新当前页码
            this.fetchData(this.currentPage);
        },
        toActor(items) {
            // 这里可以添加你点击某个 item 的逻辑，比如跳转页面
            this.$router.push({ name: "actor", params: { name: items.name } });
        }
    }
};
</script>

<style scoped>
.searchBox {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    justify-content: center;
}

.item {
    width: 19%;
    box-sizing: border-box;
    padding: 10px;
    text-align: center;
    background: #f6f7fa;
    margin: 3px;
    transition: transform 0.2s;
}

.image-container {
    position: relative;
    width: 100px;
    height: 100px;
    margin: auto;
}

.avatar-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    /* 确保图片按比例裁剪 */
    border-radius: 50%;
    /* 使图片圆形 */
}

.loading-spinner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100px;
    height: 100px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.item-name {
    margin-top: 5px;
    font-size: 14px;
    color: #333;
}
.actressTag{
    margin: 1px;
}

@media (max-width: 900px) {
    .item {
        width: 33.33%;
        /* 100% / 3 */
    }
}

@media (max-width: 600px) {
    .item {
        width: 48%;
        /* 100% / 2 */
    }
}
</style>