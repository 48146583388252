<template>
    <div class="app-container">
        <!-- 聊天室顶部导航 -->
        <header class="chat-room-header">
            <div class="left">
                <h1>聊天室</h1>
                <div class="quick-entry">⚡ 快速進入房間</div>
            </div>
            <div class="right">
                <el-button type="text">更多</el-button>
            </div>
        </header>

        <!-- 快速入口房间 -->
        <div class="room-cards">
            <el-row :gutter="20">
                <el-col :span="8" v-for="(item, index) in chatRoomList" :key="index">
                    <div :class="['card', item.styleClass]" @click="goChatRoom(item.id)">
                        <h4>{{ item.name }}</h4>
                        <p>{{ item.icon }}{{ item.joinCnt }}</p>
                    </div>
                </el-col>
            </el-row>
        </div>

        <!-- 推荐朋友部分 -->
        <section class="recommend-friends" v-show="show">
            <h2>推荐朋友</h2>
            <div class="friends-list">
                <!-- 使用 v-for 循环渲染好友卡片 -->
                <div v-for="friend in friends" :key="friend.id" class="friend-card">
                    <div class="friend-avatar">
                        <img :src="friend.avatar" alt="头像" />
                    </div>
                    <div class="friend-info">
                        <h3>{{ friend.name }}</h3>
                        <p>{{ friend.age }}岁 | {{ friend.height }}cm</p>
                        <div class="friend-tags">
                            <el-tag size="mini">{{ friend.location }}</el-tag>
                            <el-tag type="success" size="mini">{{ friend.status }}</el-tag>
                        </div>
                    </div>
                    <div class="friend-popularity">
                        <el-tag type="warning">{{ friend.popularity }}</el-tag>
                    </div>
                </div>
            </div>

        </section>
    </div>
</template>

<script>
export default {
    data() {
        return {
            chatRoomList: [],
            show: false,
            friends: [
                {
                    id: 1,
                    name: '包小图',
                    age: 24,
                    height: 172,
                    location: '上海',
                    status: '运动',
                    popularity: 522,
                    avatar: 'path_to_avatar_1'
                },
                {
                    id: 2,
                    name: '包小图',
                    age: 24,
                    height: 172,
                    location: '上海',
                    status: '运动',
                    popularity: 522,
                    avatar: 'path_to_avatar_2'
                },
                {
                    id: 3,
                    name: '包小图',
                    age: 24,
                    height: 172,
                    location: '上海',
                    status: '运动',
                    popularity: 522,
                    avatar: 'path_to_avatar_3'
                }
            ]
        };
    },
    mounted() {
        this.getChatRoomListApi()
    },
    methods: {
        getChatRoomListApi() {
            this.$loading.show();
            this.$get("api/v1/getChatRoomList/", {}).then(res => {
                this.chatRoomList = res.body
                this.$loading.hide();
            }, err => {
                this.$loading.hide();
                this.$msgError(err.message)
            })
        },
        goChatRoom(roomid) {
            this.$post("api/v1/roomLink/", { roomId: roomid }).then(res => {
                this.$router.push({ name: "ChatRoomMsg", params: { roomid } });
            }, err => { })

        },
    }
};
</script>

<style scoped>
.app-container {
    padding: 20px;
}

.chat-room-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chat-room-header .left {
    display: flex;
    flex-direction: column;
}

.chat-room-header h1 {
    margin: 0;
}

.room-cards {
    margin-top: 20px;
}

.card {
    padding: 20px 10px;
    border-radius: 12px;
    color: white;
    text-align: center;
    font-weight: bold;
    height: 100px;
}

.hot-room {
    background-color: #7a77ea;
}

.cpdd-room {
    background-color: #f29b84;
}

.online-kf {
    background-color: #45b0ef;
}

.recommend-friends {
    margin-top: 40px;
}

.friends-list {
    margin-top: 20px;
}

.friend-card {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 12px;
    background-color: #f9f9f9;
    margin-bottom: 10px;
}

.friend-avatar img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.friend-info {
    flex-grow: 1;
    margin-left: 20px;
}

.friend-info h3 {
    margin: 0;
}

.friend-tags {
    margin-top: 5px;
}

.friend-popularity {
    margin-left: 10px;
}
</style>