import Vue from "vue";
import VueRouter from "vue-router";

// 全局的导航守卫 修复导航到相同路由的错误
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};

Vue.use(VueRouter);

// 引入页面相关组件
import HomePage from '../components/indexPage.vue';
import ActressPage from '../components/actressPage.vue';
import ChatPage from '../components/chatPage.vue';
import ProfilePage from '../components/profilePage.vue';
import LoginPage from "../components/pages/LoginPage.vue";

import ChatRoomMsg from "../components/pages/ChatRoomMsg.vue";
import AllDataPage from "../components/pages/AllDataPage.vue";
import MyFavorites from "../components/pages/MyFavorites.vue";
import ActorPage from "../components/pages/ActorPage.vue";
import VideoPlayerPage from "../components/pages/VideoPlayerPage.vue";
import VipPage from "../components/pages/VipPage.vue";
import MediaPage from "../components/pages/MediaPage.vue";

const routes = [
  // {
  //   path: '/',
  //   redirect: '/index',  // 默认重定向到首页 path=/index的页面
  // },
  {
    path: '/login',
    component: LoginPage,
    name: 'Login',
    meta: { requiresTabbar: false }, // un Tabbar
  },
  {
    path: '/',
    name: 'indexPage',
    component: HomePage,
    meta: { requiresTabbar: true }, // Show Tabbar
  },
  {
    path: '/actress',
    name: 'actress',
    component: ActressPage,
    meta: { requiresTabbar: true }, // Show Tabbar
  },
  {
    path: '/chat',
    name: 'chat',
    component: ChatPage,
    meta: { requiresTabbar: true }, // Show Tabbar
  },
  {
    path: '/profile',
    name: 'ProfilePage',
    component: ProfilePage,
    meta: { requiresTabbar: true }, // Show Tabbar
  },
  {
    path: '/chat/:roomid',
    component: ChatRoomMsg,
    name: 'ChatRoomMsg',
    meta: { requiresTabbar: false }, // un Tabbar
  },
  {
    path: "/allPage/:t",
    name: "allPage",
    component: AllDataPage,
    meta: { requiresTabbar: false }, // un Tabbar
  },
  {
    path: "/actor/:name",
    name: "actor",
    component: ActorPage,
    meta: { requiresTabbar: false }, // un Tabbar
  },
  {
    path: "/details/:id",
    name: "details",
    component: VideoPlayerPage,
    meta: { requiresTabbar: false }, // un Tabbar
  },

  {
    path: '/favorites',
    component: MyFavorites,
    name: 'MyFavorites',
    meta: { requiresTabbar: false }, // un Tabbar
  },
  {
    path: '/vip',
    component: VipPage,
    name: 'vip',
    meta: { requiresTabbar: false }, // un Tabbar
  },
  {
    path: '/media',
    component: MediaPage,
    name: 'media',
    meta: { requiresTabbar: false }, // un Tabbar
  },
];

const router = new VueRouter({
  mode: 'history', // 使用 history 模式
  routes,
});

// 挂载路由导航守卫
router.beforeEach((to, from, next) => {
  if (to.path === "/login") {
    // 检查用户是否已经登录
    if (window.sessionStorage.getItem("token")) {
      // 用户已登录，跳转到主页
      next({ path: "/" });
    } else {
      next();
    }
  } else {
    // if (!window.sessionStorage.getItem('token')) {
    //   // 用户未登录，跳转到登录页面
    //   next({ path: '/login' });
    // } else {
    //   next();
    // }
    next();
  }
});

export default router; //  暴露出去
