<template>
    <div class="containerLogin">
        <div class="image-section">
            <img :src="defaultBgImg1" class="bgImg" v-if="showLogo">
            <img :src="defaultBgImg2" class="bgImg" v-else>
            <div class="back-home" @click="backHome">
                <div class="bh-div">
                    <img sizes="100vh" class="bh-index" height="33" width="100" alt="" :src="backHomeBg">
                    <div class="bh-back">返回首頁</div>
                </div>
            </div>
        </div>

        <div class="login-section">
            <input v-model="nickname" type="text" placeholder="輸入用戶名" @keyup.enter="login" />
            <input v-model="password" type="password" placeholder="輸入密碼" @keyup.enter="login" />

            <!-- 验证码输入 -->
            <div v-if="requireCaptcha" class="captcha-section">
                <input v-model="captchaCode" type="text" placeholder="輸入驗證碼" maxlength="6" />
                <button class="captcha-btn" :disabled="countdown > 0" @click="sendCaptcha">
                    {{ countdown > 0 ? `${countdown}s 後重新發送` : '發送驗證碼' }}
                </button>
            </div>

            <span style="color: var(--cs-font-color);font-size: 12px;padding: 10px;">
                <i class="el-icon-question"></i>登錄即表示同意註冊使用
            </span>
            <button class="login-btn" @click="login">登錄</button>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            showLogo: this.$showBgImg,
            defaultBgImg1: "https://kanku-static.s3.amazonaws.com/kanku-media/ea8246b1-b609-4666-a036-245cfc211d21",
            defaultBgImg2: "https://kanku-static.s3.amazonaws.com/kanku-media/4dff0514-2e0c-4e04-a53c-efa0c9f0308c",
            backHomeBg: "https://kanku-static.s3.amazonaws.com/kanku-media/fabcbf9b-6747-4d6e-81a9-04c93e4050fa",

            baseApiUrl: "api/v1/login/",
            nickname: '',
            password: '',

            requireCaptcha: false, // 控制是否显示验证码输入框
            captchaCode: '', // 验证码输入框内容
            countdown: 0 // 验证码发送按钮的倒计时
        };
    },
    methods: {
        login() {
            // 检查输入
            if (this.requireCaptcha && this.captchaCode === '') {
                this.$msgError('請輸入驗證碼');
                return;
            }
            if (!this.nickname || !this.password) {
                this.$msgError('請輸入用戶名和密碼');
                return;
            }

            this.$loading.show();
            const payload = {
                nickname: this.nickname,
                password: this.password
            };
            if (this.requireCaptcha) {
                payload.captcha = this.captchaCode;
            }
            this.$post(this.baseApiUrl, payload).then(res => {
                this.$msgSuccess('登錄成功');
                window.sessionStorage.setItem("user", res.body.author);
                window.sessionStorage.setItem("token", res.body.token);
                window.sessionStorage.setItem("auth", res.body.auth);
                window.sessionStorage.setItem("uploadType", res.body.uploadType);
                window.sessionStorage.setItem("userinfo", JSON.stringify(res.body.userinfo));
                this.$loading.hide();
                this.$router.push({ path: "/" });
            }, err => {
                this.$loading.hide();
                this.$msgError(err.message);
            });
        },
        backHome() {
            this.$router.push({ path: "/" });
        },
        sendCaptcha() {
            if (this.countdown > 0) return; // 防止重复发送
            this.$msgSuccess('驗證碼已發送');
            this.startCountdown();
        },
        startCountdown() {
            this.countdown = 60; // 初始化倒计时为 60 秒
            const interval = setInterval(() => {
                this.countdown--;
                if (this.countdown <= 0) {
                    clearInterval(interval);
                }
            }, 1000);
        }
    }
};
</script>

<style scoped>
.containerLogin {
    display: flex;
    height: 100vh;
    align-items: stretch;
    overflow: hidden;
    margin: 0; /* 确保没有额外的外边距导致溢出 */
    padding: 0; /* 确保没有额外的内边距导致溢出 */
    box-sizing: border-box; /* 包括边框在内，避免溢出内容 */
}

html, body {
    overflow: hidden; /* 彻底清除全局滚动条 */
    margin: 0;
    padding: 0;
}

.image-section {
    flex: 1;
    position: relative;
    height: 100%;
}

.image-section .bgImg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.back-home {
    position: absolute;
    top: 20px;
    width: 100%;
}

.back-home .bh-div {
    float: left;
    position: relative;
    rotate: 180deg;
}

.back-home .bh-index {
    cursor: pointer;
}

.back-home .bh-back {
    color: #fff;
    cursor: pointer;
    font-weight: 700;
    left: 23px;
    position: absolute;
    rotate: 180deg;
    top: 6px;
}

.login-section {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: linear-gradient(to bottom, #ffffff, #edf4fc);
    /* 渐变背景 */
    padding: 50px 0;
}

.login-section input,
.captcha-section input {
    width: 80%;
    margin: 10px;
    padding: 10px;
    border: 2px solid #f9f3f1;
    border-radius: 8px;
    outline: none;
    transition: border-color 0.3s, box-shadow 0.3s;
}

.login-section input:focus,
.captcha-section input:focus {
    border-color: #94ddff;
}

.login-section input::placeholder {
    color: #ccc;
}

/* 验证码输入框和发送按钮 */
.captcha-section {
    display: flex;
    flex-direction: row;
    width: 86%;
    /* 确保总宽度与普通输入框一致 */
    margin: 10px 0;
}

.captcha-section input {
    /* 验证码输入样式 */
    flex: 1;
    /* 让输入框占据剩余空间 */
    margin-right: 10px;
    /* 与按钮之间的间隙 */
}

.captcha-btn {
    flex: none;
    border: 2px solid #96a2f5;
    border-radius: 8px;
    background-image: linear-gradient(to right, #96a2f5 0%, #46c3ff 100%);
    color: white;
    cursor: pointer;
    transition: background-color 0.3s;
    margin: 10px 10px 10px 0;
    padding: 0px 10px;
}

.captcha-btn:disabled {
    /* 按钮禁用样式 */
    background-color: #ccc;
    border-color: #ccc;
    cursor: not-allowed;
}

/* 登录按钮 */
.login-btn {
    width: 80%;
    padding: 10px;
    background-image: linear-gradient(to right, #96a2f5 0%, #46c3ff 100%);
    border: none;
    color: white;
    cursor: pointer;
    border-radius: 8px;
    transition: all 0.3s;
}

.login-btn:hover {
    background-image: linear-gradient(to right, #46a1ff, #0033cc);
}

.login-btn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

@media (max-width: 768px) {
    .containerLogin {
        flex-direction: column;
    }

    .image-section {
        height: 300px;
        background: linear-gradient(to bottom, #46c3ff, #edf4fc);
    }

    .image-section .bgImg {
        object-fit: cover;
        position: absolute;
        opacity: 0.8;
    }

    .captcha-section {
        width: 90%;
    }

    .login-section {
        padding: 30px 0;
        position: relative;
        top: -20%;
        border-radius: 20px;
        flex: none;
    }
}
</style>