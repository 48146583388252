<template>
    <div class="vip-section">
        <div class="vip-content">
            <span class="vip-text">VIP</span>
            <span class="vip-desc">會員特權 暢享全年</span>
            <button class="vip-btn" @click="toVipPage">
                開通會員
                <i class="icon el-icon-arrow-right" style="font-size: 0.9rem;"></i>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
        toVipPage() {
            // this.$router.push({ path: "/vip" });
            this.$msgSuccess("未開啓會員購買-在線客服中獲取")
        },
    }
}
</script>

<style>
/* 会员权益样式 */
.vip-section {
    background: url('https://kanku-static.s3.amazonaws.com/kanku-media/0a2cbbd9-566f-4687-a6db-34ae7a4d7740') no-repeat center;
    background-size: cover;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    background-color: rgba(0, 0, 0, 0.9);
    height: 40px;
    padding: 10px;
    width: 98%;
    margin: 10px auto;
}

.vip-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.vip-text {
    font-size: 1.1rem;
    color: #ffc800;
    font-weight: bold;
}

.vip-desc {
    color: #fff;
    font-size: 12px;
    margin-left: -85px;
}

.vip-btn {
    background-color: #f5ce86;
    color: #333;
    border-radius: 20px;
    padding: 5px 20px;
    border: none;
    display: flex;
    align-items: center;
    font-size: 0.9rem;
    cursor: pointer;
    transition: background-color 0.3s;
    font-weight: 700;
}

.vip-btn i {
    margin-left: 10px;
    color: #666;
    font-weight: 700;
    font-size: 0.9rem;
}

@media only screen and (max-width: 767px) {
    .vip-section {
        width: 90%;
    }

    .vip-text {
        font-size: 1rem;
    }

    .vip-btn {
        font-size: 0.85rem;
        padding: 5px 15px;
    }
}
</style>