import Vue from "vue";
import App from "./App.vue";
import router from "./router";

// 注册全局使用的svg
import SvgSprite from './components/commonComponent/SvgScript.vue';
import SvgIcon from './components/commonComponent/SvgIcon.vue';
// 自定义封装element ui
import "./plugins/element.js";
// 自定义封装接口请求
import { get, post, put, deleteApi, uploadFileApi } from './plugins/http.js';
// 引入全局样式
import "./assets/globalCss/global.css";
// 引入iconfont，第三方的在main.js中引入，iconfont是矢量图标（登录界面）
import "./assets/iconfont_1/iconfont.css";


// 引入socket
// import socket from './plugins/socket.js';
// Vue.prototype.$socket = socket;
// this.$socket.on('onPrompt', data => {
//   this.$msgSuccess(data.data)
// });

Vue.prototype.$get = get;
Vue.prototype.$post = post;
Vue.prototype.$put = put;
Vue.prototype.$deleteApi = deleteApi;
Vue.prototype.$uploadFileApi = uploadFileApi;
Vue.prototype.$baseUrl = process.env.VUE_APP_BASE_URL; // api的base_url
Vue.prototype.$signSecretKey = process.env.SIGN_SECRET_KEY;

// Vue 启动时的生产提示
Vue.config.productionTip = false;

// 引入插件
import modalPlugin from './plugins/modal.js';
import Loading from './plugins/loading.js';
import { initSafetyFeatures } from './plugins/antiDebug.js'; // 引入防调试文件
Vue.use(Loading);
Vue.use(modalPlugin);
// 初始化反调试功能
initSafetyFeatures();

// 全局范围内使用这些封装的通知方法 - 调用 this.$notifySuccess(message)
Vue.prototype.$msgSuccess = function (message, title = 'Success') {
  this.$notify.success({ title: title, message: message });
};
Vue.prototype.$msgError = function (message, title = 'Fail') {
  this.$notify.error({ title: title, message: message });
};


// copy text
Vue.prototype.$copyText = function (copyStr) {
  navigator.clipboard.writeText(copyStr).then(() => {
    this.$notify.success({ title: "Success", message: "已復製內容" });
  }).catch(err => {
    console.error('Failed to copy text: ', err);
  });
};

Vue.prototype.$showBgImg = process.env.VUE_APP_DEBUG === 'true'; // 是否开启默认背景图

// 注册 SvgSprite 组件，让它一直渲染到页面
new Vue({
  render: (h) => h(SvgSprite),
}).$mount('#svg-sprite');
Vue.component('SvgIcon', SvgIcon);


new Vue({
  router,
  created() {

  },
  render: (h) => h(App),
}).$mount("#app");
