import axios from 'axios';
import router from "../router";
import CryptoJS from 'crypto-js';  // 引入crypto-js库

// 创建axios实例
const service = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL, // api的base_url
    timeout: 60000 // 请求超时时间
});

// request拦截器
service.interceptors.request.use(
    config => {
        if (config.method.toUpperCase() === 'OPTIONS') {
            return config;  // 直接返回 config 继续发送 OPTIONS 请求
        } else {
            const token = window.sessionStorage.getItem("token");
            config.headers['Accept'] = 'application/json';
            config.headers['Authorization'] = 'Bearer ' + token; // 确保 token 已经定义
            return config;
        }

    },
    error => {
        return Promise.reject(error);
    }
);

// response拦截器
service.interceptors.response.use(
    response => {
        var vCode = response.data.code;
        if (vCode == "000000") {
            return response.data;
        } else if (vCode == "400003" || vCode == "410002" || vCode == "410003" || vCode == "410004" || vCode == "420003") {
            window.sessionStorage.clear();
            router.push("/");
            return Promise.reject(response.data);
        } else {
            return Promise.reject(response.data);
        }
    },
    error => {
        if (error.response && error.response.status === 422) {
            router.push("/");
        }
        return Promise.reject(error);
    }
);

// 参数排序函数
function sortParams(obj) {
    const sortedKeys = Object.keys(obj).sort();
    let sortedObj = {};
    for (let key of sortedKeys) {
        sortedObj[key] = obj[key];
    }
    return sortedObj;
}

// 过滤无效参数
function filterInvalidParams(params) {
    let filteredParams = {};
    for (let key in params) {
        // 确保参数不是 null、undefined 或空字符串
        if (params[key] !== null && params[key] !== undefined && params[key] !== '') {
            filteredParams[key] = params[key];
        }
    }
    return filteredParams;
}

// 用于序列化对象
function serializeParams(params) {
    let serializedParams = {};
    for (let key in params) {
        if (params.hasOwnProperty(key)) {
            // 若值是对象或数组，则将其序列化为 JSON 字符串
            serializedParams[key] = typeof params[key] === 'object' ? JSON.stringify(params[key]) : params[key];
        }
    }
    return serializedParams;
}

// 生成加密sign
function generateSign(params, secretKey = process.env.VUE_APP_SIGN_SECRET_KEY) {
    let timestamp = Date.now(); // 新增：生成 timestamp
    const filteredParams = filterInvalidParams({ ...params, timestamp });  // 将 timestamp 放入参数中
    let serializedParams = serializeParams(filteredParams); // 序列化对象参数
    let sortedParams = sortParams(serializedParams);       // 对参数进行排序
    let paramStr = Object.keys(sortedParams).map(key => `${key}=${sortedParams[key]}`).join('&');
    const signStr = `${paramStr}&secretKey=${secretKey}`;  // timestamp 已经被包含在 paramStr 里了
    const sign = CryptoJS.MD5(signStr).toString();

    return { sign, timestamp };
}


// 封装get方法
export function get(url, params = {}) {
    return new Promise((resolve, reject) => {
        service.get(url, { params: params })
            .then(response => {
                resolve(response);
            })
            .catch(err => {
                reject(err);
            });
    });
}

// 封装post方法
export function post(url, data = {}) {
    return new Promise((resolve, reject) => {
        // 对参数进行排序并生成签名和时间戳
        const { sign, timestamp } = generateSign(data);
        // 将签名和时间戳加入 header，同时序列化对象参数发送
        const serializedData = serializeParams(data);
        // 传递序列化并带上时间戳的参数
        service.post(url, { ...serializedData, timestamp }, { headers: { 'X-Signature': sign } })
            .then(response => {
                resolve(response);
            }, err => {
                reject(err);
            });
    });
}

// 封装put方法 (同理）
export function put(url, data = {}) {
    return new Promise((resolve, reject) => {
        // 对参数进行排序并生成签名和时间戳
        const { sign, timestamp } = generateSign(data);
        // 将签名和时间戳加入 header，同时序列化对象参数发送
        const serializedData = serializeParams(data);
        // 传递序列化并带上时间戳的参数
        service.put(url, { ...serializedData, timestamp }, { headers: { 'X-Signature': sign } })
            .then(response => {
                resolve(response);
            }, err => {
                reject(err);
            });
    });
}


// 封装delete方法 (同理）
export function deleteApi(url, data = {}) {
    return new Promise((resolve, reject) => {
        // 对参数进行排序并生成签名和时间戳
        const { sign, timestamp } = generateSign(data);
        // 序列化对象参数
        const serializedData = serializeParams(data);
        // 按照 axios.delete 的校验进行修改，参数放到 `params` 中
        service.delete(url, {
            params: { ...serializedData, timestamp }, // 使用params传递查询参数
            headers: { 'X-Signature': sign } // 加上签名头部信息
        })
            .then(response => {
                resolve(response);
            })
            .catch(err => {
                reject(err);
            });
    });
}


// 上传图片或文件的封装
export function uploadFileApi(url, file, extraParams = {}) {
    return new Promise((resolve, reject) => {
        let formData = new FormData();
        for (let key in extraParams) {
            if (extraParams.hasOwnProperty(key)) {
                formData.append(key, extraParams[key]);
            }
        }
        let uploadType = window.sessionStorage.getItem('uploadType') || 'cdn'
        extraParams.uploadType = uploadType
        formData.append('file', file);
        const { sign, timestamp } = generateSign(extraParams);
        service.post(url, formData, {
            headers: {
                'X-Signature': sign,
                'Authorization': 'Bearer ' + window.sessionStorage.getItem("token"),  // 传递 token
            },
            params: {
                timestamp,
                uploadType
            }
        }).then(response => {
            resolve(response);
        }).catch(error => {
            reject(error);
        });
    });
}
